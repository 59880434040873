<template>
    <div class="user-details">
        <!--header-->
             <div class="container-fluid">
          <div class="row mt-3 mb-3">
            <div class="col-sm-8">
              <h1 class="m-0 text-dark">Member Profile</h1>
            </div>         
            <div class="col-md-4">
                  <div class="text-right">
                  <ol class="breadcrumb float-sm-right p-0">
                  <li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
                  <li class="breadcrumb-item"><Breadcrumbs/></li>
                  </ol>
                  </div>                  
           </div>            
          </div>       
        </div>  
        <div class="profile-header profile-bg">
            <div class="row">
                <div class="col-md-2 text-right hovereffect pl-5 pr-0 mr-0">
                    <img style="height:100%;width:100%;" :src="profileImg" alt="User Avatar" :key="updateLoggedUserInfo" class="mr-3 avatar">
                    <div class="overlay">
                        <b-button class="badge text" v-b-modal.profile_pic_modal>
                            <i class="fas fa-upload"></i> Upload Image
                        </b-button>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="profiler-detail pl-4 pr-5 ">
                        <h3>{{ profileData.info.full_name }}<span class="text-danger text-capitalize family-cat-txt">{{ familyCat || "" }}</span></h3>                            
                        <div class="d-flex">
                            <div class="left mr-5">
                                <h5 v-if="profileData.info.membership">{{ profileData.info.membership.name }} Membership</h5>
                            </div>
                            <div class="right" style="text-align: center; margin-bottom: 10px; padding: 8px;" v-show="digitalInfoView" v-if="(profileData.info.membership && profileData.info.membership.period != 999) && profileData.info.personal_details.relation == 0">
                                <div class="autorenewal">
                                    <AutoRenewalButton :page="page" :profileData="profileData" :autoRenew="autoRenew" :autoRenewData="autoRenewData" @loadMembersInfo="loadMembersInfo" />
                                </div>
                            </div>    
                        </div>
                            <div class="b-der"></div>
                            <div class="d-flex">
                                <div class="reg_date mt-3 mr-3">
                                    <div class="d-flex align-items-center">
                                        <div class="up_icon mr-4">
                                            <img src="/dist/img/profile/ico-registerd-date.png">
                                        </div>
                                        <div class="up_text">
                                            <span class="lable">Registered Date</span>
                                            <span> {{ usdate(profileData.info.membership_startdate) || "N/A"}}</span>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div class="ex_date mt-3 ml-3">
                                    <div class="d-flex align-items-center">
                                        <div class="up_icon mr-4">
                                             <img src="/dist/img/profile/ico-expired-date.png">
                                        </div>
                                        <div class="up_text" v-if="profileData.info.membership">
                                            <span  class="lable">Expiration Date</span>
                                            <span> {{ profileData.info.membership.period != 999 ? usdate(profileData.info.membership_endtate) : "N/A"}}</span>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                           
                        </div>
                    </div>
                    <div class="col-md-4 pl-0 pr-5" v-if="profileData.info.membership">
                        <div class="mcard">
                            <membercard :member-id="profileData.info.member_id" :key="updateLoggedUserInfo"></membercard>
                        </div>
                        <div class="text-center mt-3">
                            <a href="javascript:void(0)" @click="refreshCard" class="refreshCardLink"><i class="fas fa-redo" :class="refreshProgress ? 'fa-spin' : ''"></i> Refresh Card</a>
                        </div>
                    </div>
                </div>
            </div>
            <!--end header-->

            <!-- profile body start-->
        <div class="profile-body mt-4">
            <custom-alert v-if="displayAlert==true" id="alert_box" :message="alertMessage" v-on:showedAlert="resetAlert"></custom-alert>
            <custom-alert v-if="displayMissingMemFields==true" :message="alertMessage" v-on:showedAlert="resetAlert"></custom-alert>
            <div  class="row">
                <div  class="col-md-6 mb-3">
                    <div class="card">
                        <div class="card-header">
                            <div class="header-title" v-if="props_fields && props_fields.personal">
                                <h3 v-if="digitalInfoView">{{ (subdomain=='shooting'&&profileData.info.membership.member_type_id==7)?'Personal Information':props_fields.personal.tab_title || 'Personal Information' }}</h3>
                                <h3 v-if="!digitalInfoView">{{ (profileData.info.membership.name == "Staff")?'Coach Information':props_fields.personal.tab_title || 'Personal Information' }}</h3>
                            </div>
                            <div  class="modal-btn"><!-- Temp edit for shooting -->
                                <b-button v-b-modal.modal-center1  @click="resetprofileData()"><span class="fa fa-edit"></span> Edit</b-button>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row" v-if="props_fields && props_fields.personal">
                                <div  class="col-md-6 col-lg-4" v-if="props_fields.personal.title">
                                    <div class="form-group">
                                        <label class="form-label">{{props_fields.personal.title.label || 'Title'}}</label>
                                        <span >{{ profileData.info.title || "N/A"}}</span>
                                    </div>
                                </div>
                                <div  class="col-md-6 col-lg-4 ">
                                    <div class="form-group">
                                        <label class="form-label">{{props_fields.personal.firstname.label || 'First Name'}}</label>
                                        <span >{{ profileData.info.first_name || "N/A"}}</span>
                                    </div>
                                </div>
                                <div  class="col-md-6 col-lg-4">
                                    <div class="form-group">
                                        <label class="form-label">{{props_fields.personal.middlename.label || 'Middle Initial'}}</label>
                                        <span >{{ profileData.info.middle_name || ""}}</span>
                                    </div>
                                </div>
                                <div  class="col-md-6 col-lg-4">
                                    <div class="form-group">
                                        <label class="form-label" >{{props_fields.personal.lastname.label || 'Last Name'}}</label>
                                        <span >{{ profileData.info.last_name || "N/A"}}</span>
                                    </div>
                                </div>
                                <div  class="col-md-6 col-lg-4">
                                    <div class="form-group">
                                        <label class="form-label">{{props_fields.personal.birthday.label || 'Birthday'}}</label>
                                        <span >{{ profileData.info.dob || "N/A"}}</span>
                                    </div>
                                </div>
                                <div  class="col-md-6 col-lg-4" v-if="props_fields.personal.gender">
                                    <div class="form-group">
                                        <label class="form-label">{{props_fields.personal.gender.label || 'Gender'}}</label>
                                        <span >{{ profileData.info.gender || "N/A" }}</span>
                                    </div>
                                </div>
                                <div  class="col-md-6 col-lg-4" v-if="props_fields.personal.veteran_status">
                                    <div class="form-group">
                                        <label class="form-label">{{props_fields.personal.veteran_status.label || 'Veteran Status'}}</label>
                                        <span >{{ profileData.info.veteran_status || "N/A" }}</span>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-4" v-if="props_fields.personal.clubandteam">
                                    <div class="form-group">
                                        <label class="form-label">{{props_fields.personal.clubandteam.label ||'Affiliated Clubs/Teams'}}</label>
                                        <span class="title">{{ profileData.info.affiliated_clubs_names != undefined ? profileData.info.affiliated_clubs_names : "N/A" }}</span>
                                    </div>
                                </div>
                                <!-- <div class="col-md-6 col-lg-4" v-if="props_fields.personal.clubslist && profileData.info.affiliated_clubs_names">
                                    <div class="form-group">
                                        <label class="form-label">{{props_fields.personal.clubslist.label ||'Affiliated Clubs/Teams'}}</label>
                                        <span class="title">{{ profileData.info.affiliated_clubs_names != undefined ? profileData.info.affiliated_clubs_names : "N/A" }}</span>
                                    </div>
                                </div> -->
                                <!-- Shooting Club Name -->
                                <div class="col-md-6 col-lg-4" v-if="props_fields.personal.clubslist && profileData.info.club_name&&!(subdomain=='shooting'&&profileData.info.membership.member_type_id==7)">
                                    <div class="form-group">
                                        <label class="form-label">{{props_fields.personal.clubslist.label ||'Clubs/Teams'}}</label>
                                        <span class="title">{{ profileData.info.club_name || "N/A" }}</span>
                                    </div>
                                </div>
                                <!-- Shooting Club Name -->
                                <!-- AllianceFastpitch Club Name -->
                                <div class="col-md-6 col-lg-4" v-if="props_fields.personal.team_membership_id && !digitalInfoView">
                                  <div class="form-group">
                                    <label class="form-label">{{props_fields.personal.team_membership_id.label ||'Teams'}}</label>
                                    <span class="title">{{ profileData.info.affiliated_clubs_names || "N/A" }}</span>
                                  </div>
                                </div>
                                <!-- End -->
                              <div  class="col-md-6 col-lg-4 " v-if="props_fields && props_fields.demographic && props_fields.demographic.race && (subdomain=='shooting'&&profileData.info.membership.member_type_id==7)">
                                <div class="form-group">
                                  <label class="form-label">{{props_fields && props_fields.demographic ? props_fields.demographic.race.label : 'Race' }}</label>
                                  <span >{{profileData.info.personal.diversity.name || "N/A"}}</span>
                                </div>
                              </div>
                              <div class="col-md-6 col-lg-4" v-if="props_fields.general.sport_discipline.first_choice && !(subdomain=='shooting'&&profileData.info.membership.member_type_id==7) && subdomain !='alliancefastpitch'">
                                    <div class="form-group">
                                        <label class="form-label">{{props_fields.general.sport_discipline.first_choice.label || '1st Choice'}}</label>
                                        <span class="title" v-if="props_fields.general.sport_discipline.first_choice.multiple">
                                            <ul v-if="profileData.info.disciplines.length" class="pl-3">
                                                <li v-for="(item, k) in profileData.info.disciplines" :key="'pdis-'+k">{{ item.name }}</li>
                                            </ul>
                                        </span>
                                        <span class="title" v-else>{{ sportDisciplines[profileData.info.personal.first_choice] || "NP - No Preference" }}</span>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-4" v-if="props_fields.general.sport_discipline.second_choice">
                                    <div class="form-group">
                                        <label class="form-label">{{props_fields.general.sport_discipline.second_choice.label || '2nd Choice'}}</label>
                                        <span class="title">{{ sportDisciplines[profileData.info.personal.second_choice] || "NP - No Preference" }}</span>
                                    </div>
                                </div>
                                <div  class="col-md-6 col-lg-4" v-if="props_fields.general.sport_discipline.home_federation">
                                    <div class="form-group">
                                        <label class="form-label">{{props_fields.general.sport_discipline.home_federation.label || 'Federation'}} </label>
                                        <span >{{profileData.info.personal.home_federation || "N/A"}}</span>
                                    </div>
                                </div>
                                <div  class="col-md-6 col-lg-4" v-if="props_fields.general.sport_discipline.skier_license">
                                    <div class="form-group">
                                        <label class="form-label">{{props_fields.general.sport_discipline.skier_license.label || 'Skier License'}}</label>
                                        <span >{{profileData.info.personal.skier_license || "N/A"}}</span>
                                    </div>
                                </div>
                              <div class="col-md-6 col-lg-4" v-if="!digitalInfoView && isChild">
                                <div class="form-group">
                                  <label class="form-label">AGL Player ID#</label>
                                  <span >{{profileData.info.personal_details.agl_player_id || "N/A"}}</span>
                                </div>
                              </div>
                              <div class="col-md-6 col-lg-4" v-if="!digitalInfoView">
                                <div class="form-group">
                                  <label class="form-label">Age Division</label>
                                  <span class="title">{{ profileData.info.age_division || "N/A" }}</span>
                                </div>
                              </div>
                              <div class="col-md-6 col-lg-4" v-if="!digitalInfoView && isChild">
                                <div class="form-group">
                                  <label class="form-label">Graduation Year</label>
                                  <span >{{profileData.info.personal_details.graduation_year || "N/A"}}</span>
                                </div>
                              </div>
                              <div class="col-md-6 col-lg-4" v-if="!digitalInfoView && isChild">
                                <div class="form-group">
                                  <label class="form-label">Jersey Number</label>
                                  <span >{{profileData.info.personal_details.jersey_number || "N/A"}}</span>
                                </div>
                              </div>
                              <div class="col-md-6 col-lg-4" v-if="!digitalInfoView && isChild">
                                <div class="form-group">
                                  <label class="form-label">Primary Position</label>
                                  <span >{{profileData.info.personal_details.primary_position || "N/A"}}</span>
                                </div>
                              </div>
                              <div class="col-md-6 col-lg-4" v-if="!digitalInfoView && isChild">
                                <div class="form-group">
                                  <label class="form-label">Secondary Position</label>
                                  <span >{{profileData.info.personal_details.secondary_position || "N/A"}}</span>
                                </div>
                              </div>
                              <div class="col-md-6 col-lg-4" v-if="!digitalInfoView && isChild">
                                <div class="form-group">
                                  <label class="form-label">Throws</label>
                                  <span >{{profileData.info.personal_details.throws==1?'Left':'Right'}}</span>
                                </div>
                              </div>
                              <div class="col-md-6 col-lg-4" v-if="!digitalInfoView && isChild">
                                <div class="form-group">
                                  <label class="form-label">Bats</label>
                                  <span >{{profileData.info.personal_details.bats==1?'Left':'Right'}}</span>
                                </div>
                              </div>
                              <div class="col-md-6 col-lg-4" v-if="!digitalInfoView && isChild">
                                <div class="form-group">
                                  <label class="form-label">College Committed</label>
                                  <span v-if="profileData.info.personal_details.college_details != null">{{profileData.info.personal_details.college_details.name || "N/A"}}</span>
                                  <span v-else>N/A</span>
                                </div>
                              </div>
                              <div class="col-md-6 col-lg-4" v-if="!digitalInfoView">
                                <div class="form-group">
                                  <label class="form-label">Member League</label>
                                  <span class="title">{{ profileData.info.member_league || "N/A" }}</span>
                                </div>
                              </div>
                            </div>
                            <div class="row" v-if="digitalInfoView && profileData.info.affiliated_clubs_dropdown.length == 0 && profileData.info.new_club_name">
                                <div class="col-md-12 mb-3 mt-3"><strong>New Club Information</strong></div>
                                <div class="col-md-4" v-if="profileData.info.new_club_name && props_fields && props_fields.personal && props_fields.personal.new_club_name">
                                    <div class="form-group">
                                        <label class="form-label">{{'Club Name'}}</label>
                                        <span >{{profileData.info.new_club_name || "N/A"}}</span>
                                    </div>
                                </div>
                                <div class="col-md-4" v-if="profileData.info.new_club_email && props_fields && props_fields.personal && props_fields.personal.new_club_email">
                                    <div class="form-group">
                                        <label class="form-label">{{'Club E-Mail'}}</label>
                                        <span >{{profileData.info.new_club_email || "N/A"}}</span>
                                    </div>
                                </div>
                                <div class="col-md-4" v-if="profileData.info.new_club_phone && props_fields && props_fields.personal && props_fields.personal.new_club_phone">
                                    <div class="form-group">
                                        <label class="form-label">{{'Club Phone'}}</label>
                                        <span >{{profileData.info.new_club_phone || "N/A"}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-5" >
                        <div class="card-header">
                            <h3>Membership Information</h3>
                        </div>
                        <div class="card-body">
                            <div  class="row">
                                <div  class="col-md-4">
                                    <div class="form-group">
                                        <label class="form-label">Membership Type</label>
                                        <span>{{ profileData.info.membership.name }}</span>
                                    </div>
                                </div>
                                <div  class="col-md-4">
                                    <div class="form-group">
                                        <label class="form-label">Start Date</label>
                                        <span>{{ usdate(profileData.info.membership_startdate) || "N/A"}}</span>
                                    </div>
                                </div>
                                <div  class="col-md-4">
                                    <div class="form-group">
                                        <label class="form-label">Expiration Date</label>
                                        <span>{{ profileData.info.membership.period != 999 ? usdate(profileData.info.membership_endtate) : "N/A"}}</span>
                                    </div>
                                </div>
                                <div  class="col-md-4 ">
                                    <div class="form-group">
                                        <label class="form-label">Membership #</label>
                                        <span>{{ profileData.info.confirmation_code || "N/A"}}</span>
                                    </div>
                                </div>
                                <div  class="col-md-4" v-show="digitalView">
                                    <div class="form-group">
                                      <label class="form-label">Membership Status <template><div class="d-inline ml-2" id="tooltip" v-if="subdomain != 'shooting' && digitalInfoView"><i class="fas fa-info-circle"></i></div><b-tooltip target="tooltip" class="white">{{profileData.info.membership_status_info}} <br> Click here to review all Membership Statuses - <a href="https://www.teamusa.org/usa-water-ski/membership-status-types" target="_blank">https://www.teamusa.org/usa-water-ski/membership-status-types</a></b-tooltip></template></label>
                                        <span class="text-success" v-if="profileData.info.membership_status == 1"><img class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ profileData.info.membership_status_text }} (Expires {{this.validThru}})</span>
                                        <span class="text-warning"  v-else-if="profileData.info.membership_status >=7"> <img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{ profileData.info.membership_status_text }}</span>
                                        <span class="text-danger"  v-else> <img style="width:18px" class="mr-1" src="/dist/img/profile/deactivated.png"> {{ profileData.info.membership_status_text }}</span>
                                    </div>
                                </div>
                                    <div  class="col-md-4" v-show="digitalView&&!(subdomain=='shooting'&&profileData.info.membership.member_type_id==7)">
                                    <div class="form-group">
                                        <label class="form-label">Waiver Status</label>
                                        <!-- <template v-if="(subdomain == 'shooting' &&  profileData.info.registration[0] != undefined && profileData.info.registration[0].waivers != undefined && profileData.info.registration[0].waivers.length) || (subdomain == 'waterski' && profileData.info.personal_details.relation == 2)"> -->
                                        <template v-if="!ignoreWaiverMembership.includes(profileData.info.membership.id)">
                                            <span class="text-success" v-if="profileData.info.waiver_status == 1"><img class="mr-1" src="/dist/img/profile/ico-status-current.png"> Signed (Expires {{ profileData.info.membership.period != 999 ? usdate(profileData.info.membership_endtate) : "N/A"}})</span>
                                            <span class="text-warning"  v-else> <img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> Not signed</span>
                                        </template>
                                        <span v-else>N/A</span>
                                    </div>
                                </div>
                                <template v-if="subdomain == 'waterski'">
                                    <div  class="col-md-4" v-show="digitalView">
                                        <div class="form-group" v-if="profileData.info.safe_sport_screening_compliance == true">
                                            <label class="form-label" >Safe Sport Training Status <a v-if="profileData.info.safe_sport != null" class="btn-refersh" @click.prevent="getSafeSportUserApi();">{{ safeSportRefreshButton }}</a></label>
                                            <span class="text-success" v-if="profileData.info.safe_sport != null && profileData.info.safe_sport.safe_sport_status_text == 'Complete'"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ profileData.info.safe_sport.safe_sport_status_text}} <span class="text-success">(Expires {{ profileData.info.safe_sport.expiration }})</span></span>
                                            <span class="text-warning" v-else-if="profileData.info.safe_sport != null"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{profileData.info.safe_sport.safe_sport_status_text || "N/A"}}<template v-if="profileData.info.safe_sport.safe_sport_status == 13 && profileData.info.safe_sport.expiration"><br>(On {{ profileData.info.safe_sport.expiration }})</template></span>
                                            <span class="text-warning" v-else>Not Started</span>
                                        </div>
                                        <div class="form-group" v-else>
                                            <label class="form-label">Safe Sport Training Status</label>
                                            <span class="">N/A</span>
                                        </div>
                                    </div>
                                    <div  class="col-md-4" v-show="digitalView" v-if="digitalInfoView">
                                        <div class="form-group" v-if="profileData.info.safe_sport_screening_compliance == true || (profileData.info.membership.id == 4 && age > 17)">
                                            <label class="form-label">Background Screening Status <span v-if="profileData.info.background_screening != null && profileData.info.background_screening.background_screening_info" v-b-tooltip.hover :title="profileData.info.background_screening.background_screening_info"> <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" />
                                                </span>
                                                <template v-if="props_tooltips && props_tooltips.backgroundScreening">
                                                    <div class="d-inline" id="bgscreen-tooltip" v-b-tooltip.hover="{ customClass: 'bgscreen-tooltip' }">
                                            <i class="fas fa-info-circle"></i>
                                                    </div>
                                                    <b-tooltip custom-class="bgscreenTooltip" target="bgscreen-tooltip" class="white">                                        
                                                        <p class="mb-0" v-html="props_tooltips.backgroundScreening"></p>
                                                    </b-tooltip>
                                                </template>
                                    </label> 
                                            <span class="text-success" v-if="profileData.info.background_screening != null && profileData.info.background_screening.status == 2 && profileData.info.background_screening.background_screening_status_text != 'In Complete'"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ profileData.info.background_screening.background_screening_status_text || "N/A"}} <span v-if="profileData.info.background_screening.expiration" class="text-success"><br>(Expires {{ profileData.info.background_screening.expiration | formatDob }})</span></span>
                                            <span class="text-danger" v-else-if="(profileData.info.background_screening != null && profileData.info.background_screening.status == 7) || (profileData.info.background_screening != null && profileData.info.background_screening.status == 3)"><img style="width: 18px" class="mr-1" src="/dist/img/profile/deactivated.png"> {{ profileData.info.background_screening.background_screening_status_text || "N/A"}} <br>(On {{ profileData.info.background_screening.updated_at | formatDob }})</span>
                                            <span class="text-warning" v-else-if="profileData.info.background_screening != null"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{profileData.info.background_screening.background_screening_status_text || "N/A"}}<template v-if="profileData.info.background_screening.status == 6 && profileData.info.background_screening.expiration"><br>(On {{ profileData.info.background_screening.expiration | formatDob }})</template></span>
                                            <span class="text-warning" v-else>Not Started</span>
                                        </div>
                                        <div class="form-group" v-else>
                                            <label class="form-label">Background Screening Status</label>
                                            <span class="">N/A</span>
                                        </div>
                                    </div>
                                  <div  class="col-md-4" v-show="digitalView" v-if="digitalInfoView">
                                    <div class="form-group" v-if="age >= 16">
                                      <label class="form-label">MVR Status <span v-if="profileData.info.background_screening != null && profileData.info.background_screening.background_screening_info" v-b-tooltip.hover :title="profileData.info.background_screening.background_screening_info"> <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" />
                                                </span>
                                        <template v-if="props_tooltips && props_tooltips.backgroundScreening">
                                          <div class="d-inline" id="bgscreen-tooltip" v-b-tooltip.hover="{ customClass: 'bgscreen-tooltip' }">
                                            <i class="fas fa-info-circle"></i>
                                          </div>
                                          <b-tooltip custom-class="bgscreenTooltip" target="bgscreen-tooltip" class="white">
                                            <p class="mb-0" v-html="props_tooltips.backgroundScreening"></p>
                                          </b-tooltip>
                                        </template>
                                      </label>
                                      <span class="text-success" v-if="profileData.info.background_mvr_review != null && profileData.info.background_mvr_review.status == 2 && profileData.info.background_mvr_review.background_screening_status_text != 'In Complete'"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ profileData.info.background_mvr_review.background_screening_status_text || "N/A"}} <span v-if="profileData.info.background_mvr_review.expiration" class="text-success"><br>(Expires {{ profileData.info.background_mvr_review.expiration | formatDob }})</span></span>
                                      <span class="text-danger" v-else-if="(profileData.info.background_mvr_review != null && profileData.info.background_mvr_review.status == 7) || (profileData.info.background_mvr_review != null && profileData.info.background_mvr_review.status == 3)"><img style="width: 18px" class="mr-1" src="/dist/img/profile/deactivated.png"> {{ profileData.info.background_mvr_review.background_screening_status_text || "N/A"}} <br>(On {{ profileData.info.background_mvr_review.updated_at | formatDob }})</span>
                                      <span class="text-warning" v-else-if="profileData.info.background_mvr_review != null"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{profileData.info.background_mvr_review.background_screening_status_text || "N/A"}}<template v-if="profileData.info.background_mvr_review.status == 6 && profileData.info.background_mvr_review.expiration"><br>(On {{ profileData.info.background_mvr_review.expiration | formatDob }})</template></span>
                                      <span class="text-warning" v-else>Not Started</span>
                                    </div>
                                    <div class="form-group" v-else>
                                      <label class="form-label">MVR Status</label>
                                      <span class="">N/A</span>
                                    </div>
                                  </div>
                                </template>
                                <template v-if="subdomain == 'shooting'&&profileData.info.membership.member_type_id!=7">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                                <label class="form-label">Safe Sport Training Status <a class="btn-refersh" @click.prevent="getSafeSportUserApi();">{{ safeSportRefreshButton }}</a></label>
                                                <template>
                                                    <span class="text-success" v-if="profileData.info.safe_sport != null && profileData.info.safe_sport.safe_sport_status_text == 'Complete'"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ profileData.info.safe_sport.safe_sport_status_text}} <span v-if="profileData.info.safe_sport.expiration" class="text-success">(Expires {{ profileData.info.safe_sport.expiration }})</span></span>
                                                    <span class="text-warning" v-else-if="profileData.info.safe_sport != null"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{profileData.info.safe_sport.safe_sport_status_text || "N/A"}}<template v-if="profileData.info.safe_sport.safe_sport_status == 13 && profileData.info.safe_sport.expiration"><br>(On {{ profileData.info.safe_sport.expiration }})</template></span>
                                                    <span class="text-warning" v-else>Not Started</span>
                                                </template>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                                <label class="form-label">Background Screening Status <span v-if="profileData.info.background_screening != null && profileData.info.background_screening.background_screening_info" v-b-tooltip.hover :title="profileData.info.background_screening.background_screening_info"> <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" /></span></label>
                                                <template>
                                                  <span class="text-success" v-if="profileData.info.background_screening != null && profileData.info.background_screening.status == 2"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ profileData.info.background_screening.background_screening_status_text || "N/A"}}<span v-if="profileData.info.background_screening.expiration"> <span v-if="profileData.info.background_screening.expiration" class="text-success"><br>(Expires {{ profileData.info.background_screening.expiration | formatDob }})</span></span></span>
                                                    <span class="text-danger" v-else-if="(profileData.info.background_screening != null && profileData.info.background_screening.status == 7) || (profileData.info.background_screening != null && profileData.info.background_screening.status == 3)"><img  class="mr-1"  style="width: 18px" src="/dist/img/profile/deactivated.png"> {{ profileData.info.background_screening.background_screening_status_text || "N/A"}} <br>(On {{ profileData.info.background_screening.updated_at | formatDob }})</span>
                                                    <span class="text-warning" v-else-if="profileData.info.background_screening != null"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{profileData.info.background_screening.background_screening_status_text || "N/A"}}<template v-if="profileData.info.background_screening.status == 6 && profileData.info.background_screening.expiration"><br>(On {{ profileData.info.background_screening.expiration | formatDob }})</template></span>
                                                    <span class="text-warning" v-else>Not Started</span>
                                                </template>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-5" v-if="getSiteProps('group_model.visible') && memberGroupVisible">
                        <div class="card-header ">
                            <div class="header-title">
                                <h3 >Group Information</h3>
                            </div>
                        </div>
                        <div class="card-body">
                            <div  class="row">
                                <div  class="col-md-12"> 
                                    <div class="mb-3" v-for="(tags,tag) in memberGroups" :key="tags" >
                                        <label class="form-label group_tag_title"> <strong> {{ tag }} </strong> </label>
                                        <ul class="b-form-tags-list list-unstyled mb-0 d-flex flex-wrap align-items-center">
                                            <li v-for="(group,i) in tags" :key="i" class="m-1 p-1 badge b-form-tag d-inline-flex align-items-baseline mw-100 btn-tag-blue btn-primary text-white"><span class="b-form-tag-content flex-grow-1 text-truncate" style="font-size: 13px;">{{ group }}</span></li>
                                        </ul>
                                    </div>
                                </div>           
                            </div>
                        </div>
                  </div>
                </div>
                <div  class="col-md-6">
                    <div class="card">
                        <div class="card-header">
                            <div class="header-title">
                                <h3 >Address Information</h3>                                
                            </div>
                            <div  class="modal-btn" v-if="profileData.info.personal.relation===0 || profileData.info.personal.relation===1">
                                <b-button v-b-modal.modal-center><span class="fa fa-edit"></span> Edit</b-button>
                            </div>
                        </div>
                        <div class="card-body">
                            <div  class="row">
                                <div class="col-md-12 col-lg-12" v-if="props_fields.contact.address_type">
                                    <div class="form-group">
                                        <label class="form-label">{{props_fields.contact.address_type.label || 'Address Type'}}</label>
                                        <span >{{ profileData.info.address_type || "N/A"}}</span>
                                    </div>
                                </div>
                                <div  class="col-md-6 col-lg-4" v-if="props_fields.contact.address1">
                                    <div class="form-group">
                                        <label class="form-label">{{props_fields.contact.address1.label || 'Mailling Address'}}</label>
                                        <span >{{ profileData.info.address1 || ""}}</span>
                                    </div>
                                </div>
                                <div  class="col-md-6 col-lg-4" v-if="props_fields.contact.address2">
                                    <div class="form-group">
                                        <label class="form-label">{{props_fields.contact.address2.label || 'Address2'}}</label>
                                        <span >{{ profileData.info.address2 || ""}}</span>
                                    </div>
                                </div>
                                <div  class="col-md-6 col-lg-4" v-if="props_fields.contact.city">
                                    <div class="form-group">
                                        <label class="form-label">{{props_fields.contact.city.label || 'City'}}</label>
                                        <span >{{ profileData.info.city || ""}}</span>
                                    </div>
                                </div>
                                <div  class="col-md-6 col-lg-4" v-if="props_fields.contact.state_code">
                                    <div class="form-group">
                                        <label class="form-label">{{props_fields.contact.state_code.label || 'State'}}</label>
                                      <span v-if="profileData && profileData.info && (profileData.info.country === 'USA')">{{ profileData.info.state_code }}</span>
                                      <span v-else-if="profileData && profileData.info && profileData.info.state">{{ profileData.info.state }}</span>
                                      <span v-else>N/A</span>
                                    </div>
                                </div>
                                <div  class="col-md-6 col-lg-4" v-if="props_fields.contact.country">
                                    <div class="form-group">
                                        <label class="form-label">{{props_fields.contact.country.label || 'Country'}}</label>
                                        <span>{{ profileData.info.country || "N/A"}}</span>
                                    </div>
                                </div>
                                <div  class="col-md-6 col-lg-4" v-if="props_fields.contact.zip">
                                    <div class="form-group">
                                        <label class="form-label">{{props_fields.contact.zip.label || 'Postal Code'}}</label>
                                        <span>{{ profileData.info.zip || ""}}</span>
                                    </div>
                                </div>
                                <div  class="col-md-6 col-lg-4" v-if="props_fields.contact.citizenship">
                                    <div class="form-group">
                                        <label class="form-label">{{props_fields.contact.citizenship.label || 'Citizenship'}}</label>
                                        <span>{{ UpdateProfile.citizenship || "N/A"}}</span>
                                    </div>
                                </div>
                                <div  class="col-md-6 col-lg-4" v-if="isChild &&props_fields.contact.parent_citizenship">
                                  <div class="form-group">
                                    <label class="form-label">{{props_fields.contact.parent_citizenship.label_text || 'Parent/Guardian Citizenship'}}</label>
                                    <span>{{ UpdateProfile.parent_citizenship || "N/A"}}</span>
                                  </div>
                                </div>
                                <div  class="col-md-6 col-lg-4" v-if="isChild &&props_fields.contact.athlete_citizenship">
                                  <div class="form-group">
                                    <label class="form-label">{{props_fields.contact.athlete_citizenship.label_text || 'Child/Athlete Citizenship'}}</label>
                                    <span>{{ UpdateProfile.athlete_citizenship || "N/A"}}</span>
                                  </div>
                                </div>
                                <div  class="col-md-6 col-lg-4" v-if="isChild &&props_fields.contact.eligible_citizenship">
                                  <div class="form-group">
                                    <label class="form-label">{{props_fields.contact.eligible_citizenship.label_text || 'Eligible Child/AthleteCitizenship'}}</label>
                                    <span>{{ UpdateProfile.eligible_citizenship || "N/A"}}</span>
                                  </div>
                                </div>
                                <div class="col-md-6 col-lg-4" v-if="getSiteProps('general.region_based_diversity') && subdomain != 'shooting' && digitalInfoView ">
                                    <div class="form-group">
                                        <label class="form-label">Region</label>
                                        <span >{{  profileData.info.country== "USA" ? profileData.info.region : "Foreign"}}</span>
                                    </div>
                                </div>                               
                                <template v-if="props_fields.contact.address_type && profileData.info.address_type == 'Work'">
                                    <div  class="col-md-6 col-lg-4" v-if="props_fields.contact.work_address_company_name">
                                        <div class="form-group">
                                            <label class="form-label">{{props_fields.contact.work_address_company_name.label || 'Company Name'}}</label>
                                            <span >{{ profileData.info.company_name || "N/A"}}</span>
                                        </div>
                                    </div>
                                    <div  class="col-md-6 col-lg-6" v-if="props_fields.contact.work_address_company_phone_extension">
                                        <div class="form-group">
                                            <label class="form-label">{{props_fields.contact.work_address_company_phone_extension.label || 'Company Phone with Extension'}}</label>
                                            <span >{{ profileData.info.company_phone ? profileData.info.company_phone+" Ext:"+(profileData.info.company_phone_extension || 'N/A') : "N/A"}}</span>
                                        </div>
                                    </div>
                                </template>
                                <template >
                                  <div class="col-md-6 col-lg-6" v-show="digitalView" v-if="enableMagazine">
                                        <div class="form-group" v-if="profileData.info.active_registration_id">
                                            <label class="form-label">Printed copy of the Water Skier magazine  </label>
                                            <span class="text-success" v-if="profileData.info.printed_magazine !=1">
                                                <img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> Subscribed 
                                            </span>
                                            <span class="text-warning" v-else><img class="mr-1" src="/dist/img/profile/ico-status-pending.png">Not Subscribed</span>
                                                <b-button  v-b-modal.edit-magazine variant="light"><span class="fa fa-edit"></span></b-button>
                                        </div>    
                                        <div class="form-group" v-else>
                                            <label class="form-label">Printed copy of the Water Skier magazine</label>
                                            <span class="text-success"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> Subscribed </span>
                                        </div>
                                </div>
                                </template>
                                <div class="form-group" v-if="profileData.info.contact_details.bad_address == 1 && subdomain == 'waterski'">
                                    <label for="bad_address" class="d-flex mr-1 text-danger"><i class="fas fa-times-circle" style="margin-top:4px"></i><b>&nbsp; UnDeliverable Address</b></label>
                                </div>
                                </div>          
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <div class="header-title">
                                <h3 >Contact Information</h3>
                            </div>
                            <div  class="modal-btn" v-if="profileData.info.personal.relation===0 || profileData.info.personal.relation===1">
                                <b-button v-b-modal.modal-center3><span class="fa fa-edit"></span> Edit</b-button>
                            </div>
                        </div>
                        <div class="card-body">
                            <div  class="row">
                                <div  class="col-md-6 col-lg-4 " v-if="!digitalInfoView && profileData.info.membership.id !=5">
                                  <div class="form-group">
                                    <label class="form-label">Parent First Name</label>
                                    <span >{{profileData.info.contact_details.first_name || "N/A"}}</span>
                                  </div>
                                </div>
                                <div  class="col-md-6 col-lg-4 " v-if="!digitalInfoView  && profileData.info.membership.id !=5">
                                  <div class="form-group">
                                    <label class="form-label">Parent Middle Name</label>
                                    <span >{{profileData.info.contact_details.middle_name || "N/A"}}</span>
                                  </div>
                                </div>
                                <div  class="col-md-6 col-lg-4 " v-if="!digitalInfoView  && profileData.info.membership.id !=5">
                                  <div class="form-group">
                                    <label class="form-label">Parent Last Name</label>
                                    <span >{{profileData.info.contact_details.last_name || "N/A"}}</span>
                                  </div>
                                </div>
                                <div  class="col-md-6 col-lg-4 ">
                                    <div class="form-group">
                                        <label class="form-label">{{props_fields.contact.homephone.label || 'Home Phone'}}</label>
                                        <span >{{ profileData.info.phone_1 || "N/A"}}</span>
                                    </div>
                                </div>
                                <div  class="col-md-6 col-lg-4">
                                    <div class="form-group">

                                        <label class="form-label">{{props_fields.contact.mobilephone.label || 'Mobile Phone'}}</label>
                                        <span >{{ profileData.info.phone_2 || "N/A"}}</span>
                                    </div>
                                </div>
                                <div  class="col-md-6 col-lg-4">
                                    <div class="form-group">
                                        <label class="form-label">{{props_fields.contact.email.label || 'Email'}}</label>
                                        <span><a class="email-link" v-bind:href="'mailto:' + profileData.info.email" >{{ profileData.info.email || "N/A"}}</a></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  <div class="pb-5" v-if="digitalInfoView&&!(subdomain=='shooting'&&profileData.info.membership.member_type_id==7)" v-show="digitalView">
                        <div class="card mb-5">
                            <div class="card-header">
                                <div class="header-title">
                                    <h3>{{ props_fields.demographic.title || 'Additional Information'}}</h3>
                                </div>
                                <div  class="modal-btn" v-if="profileData.info.personal.relation===0 || profileData.info.personal.relation===1">
                                    <b-button v-b-modal.edit-demographic><span class="fa fa-edit"></span> Edit</b-button>
                                </div>
                            </div>
                            <div class="card-body">
                                <div  class="row">
                                    <div  class="col-md-6 col-lg-4 " v-if="props_fields && props_fields.demographic && props_fields.demographic.race">
                                        <div class="form-group">
                                            <label class="form-label">{{props_fields && props_fields.demographic ? props_fields.demographic.race.label : 'Race' }}</label>
                                            <span >{{profileData.info.personal.diversity.name || "N/A"}}</span> 
                                        </div>
                                    </div>
                                    <div  class="col-md-6 col-lg-4" v-if="props_fields && props_fields.demographic && props_fields.demographic.ethnicity">
                                        <div class="form-group">
                                            <label class="form-label">{{props_fields && props_fields.demographic ? props_fields.demographic.ethnicity.label : 'Ethnicity' }}</label>
                                            <span >{{profileData.info.personal.ethnicity || "N/A"}}</span>
                                        </div>
                                    </div>
                                    <div  class="col-md-6 col-lg-4" v-if="props_fields && props_fields.demographic && props_fields.demographic.veteran_status">
                                        <div class="form-group">
                                            <label class="form-label">{{props_fields && props_fields.demographic ? props_fields.demographic.veteran_status.label : 'Veteran Status' }}</label>
                                            <span >{{profileData.info.personal.veteran_status || "N/A"}}</span>
                                        </div>
                                    </div>
                                    <div  class="col-md-6 col-lg-4" v-if="props_fields && props_fields.demographic && props_fields.demographic.disability_status">
                                        <div class="form-group">
                                            <label class="form-label">{{props_fields && props_fields.demographic ? props_fields.demographic.disability_status.label : 'Disability Status' }}</label>
                                            <span >{{profileData.info.personal.disability || "N/A"}}</span>
                                        </div>
                                    </div>
                                    <div  class="col-md-6 col-lg-4" v-if="props_fields && props_fields.demographic && props_fields.demographic.primary_area_interest">
                                        <div class="form-group">
                                            <label class="form-label">{{props_fields && props_fields.demographic ? props_fields.demographic.primary_area_interest.label : 'Primary Role of Interest' }}</label>
                                            <span >{{profileData.info.personal.primary_area_interest || "N/A"}}</span>
                                        </div>
                                    </div>
                                    <div  class="col-md-6 col-lg-4" v-if="props_fields && props_fields.demographic && props_fields.demographic.category">
                                        <div class="form-group">
                                            <label class="form-label">{{props_fields && props_fields.demographic ? props_fields.demographic.category.label : 'Category' }}</label>
                                            <span >{{profileData.info.personal.shooting_category ? profileData.info.personal.shooting_category.name : "N/A"}}</span>
                                        </div>
                                    </div>
                                    <div  class="col-md-6 col-lg-6" v-if="props_fields && props_fields.demographic && props_fields.demographic.issf">
                                        <div class="form-group">
                                            <label class="form-label">{{props_fields && props_fields.demographic ? props_fields.demographic.issf.label : 'ISSF License Certification Number' }} </label>
                                            <span >{{profileData.info.personal.issf || "N/A"}}</span>
                                        </div>
                                    </div>
                                    <div  class="col-md-6 col-lg-6" v-if="props_fields && props_fields.demographic && props_fields.demographic.cmp">
                                        <div class="form-group">
                                            <label class="form-label">{{props_fields && props_fields.demographic ? props_fields.demographic.cmp.label : 'CMP Membership #' }} </label>
                                            <span >{{profileData.info.personal.cmp_no || "N/A"}}</span>
                                        </div>
                                    </div>
                                    <div  class="col-md-6 col-lg-6" v-if="props_fields && props_fields.demographic && props_fields.demographic.nra">
                                        <div class="form-group">
                                            <label class="form-label">{{props_fields && props_fields.demographic ? props_fields.demographic.nra.label : 'NRA Membership #' }} </label>
                                            <span >{{profileData.info.personal.nra_no || "N/A"}}</span>
                                        </div>
                                    </div>
                                    <div  class="col-md-6 col-lg-6" v-if="props_fields && props_fields.demographic && props_fields.demographic.sasp">
                                        <div class="form-group">
                                            <label class="form-label">{{props_fields && props_fields.demographic ? props_fields.demographic.sasp.label : 'SASP Membership #' }} </label>
                                            <span >{{profileData.info.personal.sasp_no || "N/A"}}</span>
                                        </div>
                                    </div>
                                    <div  class="col-md-6 col-lg-6" v-if="props_fields && props_fields.demographic && props_fields.demographic.sctp">
                                        <div class="form-group">
                                            <label class="form-label">{{props_fields && props_fields.demographic ? props_fields.demographic.sctp.label : 'SCTP Membership #' }} </label>
                                            <span >{{profileData.info.personal.sctp_no || "N/A"}}</span>
                                        </div>
                                    </div>
                                    <div  class="col-md-6 col-lg-6" v-if="props_fields && props_fields.demographic && props_fields.demographic.hear_about">
                                        <div class="form-group">
                                            <label class="form-label">{{props_fields && props_fields.demographic ? props_fields.demographic.hear_about.label : 'How did you hear about USA Shooting' }}</label>
                                            <span >{{profileData.info.personal.hear_about || "N/A"}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- profile body end-->
        <div class="address-modal">
            <b-modal id="modal-center" ref="modal-center" size="lg" ok-title="Update" @ok="updateAddressInfo" centered title="Address Information">
                <div class="form-body">
                    <form>
                        <div class="row" v-if="props_fields.contact.address_type">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="address_type" class="form-label">{{ props_fields.contact.address_type.label }}<sup class="text-danger" v-if="props_fields.contact.address_type.required">*</sup></label>
                                    <select name="UpdateProfile.address_type" id="address_type" class="form-control" v-model="UpdateProfile.address_type">
                                        <option v-for="(item, k) in props_fields.contact.address_type.options" :key="'address-type-'+k" :value="item.value">{{ item.label }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="form-label" for="address1">{{ props_fields.contact.address1.label || 'Mailling Address'}} <sup class="text-danger" v-if="props_fields.contact.address1.required">*</sup><span v-if="props_fields.contact.address1.charLimitText" class="text-danger text-xs" style="margin-left:30px;"> {{ props_fields.contact.address1.charLimitText }}</span></label>
                                    <input type="text" id="address1" v-model="UpdateProfile.address1" :maxlength=props_fields.contact.address1.charLimitValue value="" placeholder="8080 Holister" class="form-control">
                                    <span class="text-sm text-danger">{{ errors.address1 }}</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="form-label" for="address2">{{ props_fields.contact.address2.label || 'Address2'}} <span v-if="props_fields.contact.address2.charLimitText" class="text-danger text-xs" style="margin-left:30px;">{{ props_fields.contact.address2.charLimitText }}</span></label>
                                    <input type="text" id="address2" v-model="UpdateProfile.address2" :maxlength=props_fields.contact.address2.charLimitValue value="" placeholder="Apt.400" class="form-control">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4" v-if="props_fields.contact.country">
                                <div class="form-group">
                                    <label for="Country" class="form-label">{{ props_fields.contact.country.label }} <sup class="text-danger" v-if="props_fields.contact.country.required">*</sup></label>
                                    <select class="form-control" id="country" @change="removeStateCode" v-model="UpdateProfile.country" name="UpdateProfile.country"  v-if="props_fields.contact.country.options == undefined">>
                                        <option value="">Select</option>
                                        <option :value="listOfCountry.country" v-for="listOfCountry in listOfCountries" :key="listOfCountry.country" :selected="listOfCountry.country == 'Bahamas'">{{ listOfCountry.country }}</option>
                                    </select>
                                    <select class="form-control" id="country" @change="removeStateCode" v-model="UpdateProfile.country" name="UpdateProfile.country"  v-else>
                                      <option value="">Select</option>
                                      <option :value="listOfCountry.country" v-for="listOfCountry in props_fields.contact.country.options" :key="listOfCountry.country">{{ listOfCountry.country }}</option>
                                    </select>
                                    <span class="text-sm text-danger">{{ errors.country}}</span>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="form-label" for="city">City <sup class="text-danger" v-if="props_fields.contact.city.required">*</sup></label>
                                    <input type="text" id="city" v-model="UpdateProfile.city" value="" placeholder="FL" class="form-control">
                                    <span class="text-sm text-danger">{{ errors.city }}</span>
                                </div>
                            </div>
                            <div class="col-md-4" v-if="UpdateProfile.country == 'USA'">
                                <div class="form-group">
                                    <label for="state_code" class="form-label">{{ props_fields.contact.state_code.label }} <sup class="text-danger" v-if="props_fields.contact.state_code.required">*</sup></label>
                                    <select class="form-control" id="state_code" v-model="UpdateProfile.state_code" name="UpdateProfile.state_code">
                                        <option value="">Select</option>
                                        <option  v-for="listOfState in listOfStates" :key="listOfState.state_code" :value="listOfState.state_code">{{ listOfState.state }}</option>
                                    </select>
                                    <span class="text-sm text-danger">{{ errors.state_code}}</span>
                                </div>
                            </div>
                            <div class="col-md-4" v-else-if="UpdateProfile.country == 'Canada'">
                              <div class="form-group">
                                <label for="state_code" class="form-label">{{ props_fields.contact.state_code.label }} <sup class="text-danger" v-if="props_fields.contact.state_code.required">*</sup></label>
                                <select class="form-control" id="state_code" v-model="UpdateProfile.state" name="UpdateProfile.state" v-if="digitalInfoView">
                                  <option value="">Select</option>
                                  <option  v-for="listOfState in listOfCAStates" :key="listOfState.state_code" :value="listOfState.state">{{ listOfState.state }}</option>
                                </select>
                                <select class="form-control" id="state_code" v-model="UpdateProfile.state_code" name="UpdateProfile.state_code" v-else>
                                  <option value="">Select</option>
                                  <option  v-for="listOfState in props_fields.contact.state_code.option" :key="listOfState.state_code" :value="listOfState.state_code">{{ listOfState.state }}</option>
                                </select>
                                <span class="text-sm text-danger">{{ errors.state_code}}</span>
                              </div>
                            </div>
                            <div class="col-md-4" v-else>
                                <div class="form-group">
                                    <label for="state_code" class="form-label">{{ props_fields.contact.other_state.label }}</label>
                                    <input type="text" class="form-control" maxlength="45" v-model="UpdateProfile.state" name="UpdateProfile.state">
                                    <span class="text-sm text-danger">{{ errors.state}}</span>
                                </div>
                            </div>
                            <!-- <div class="col-md-4">
                                <div class="form-group">
                                    <label class="form-label" for="state">State</label>
                                    <input type="text" id="state" v-model="UpdateProfile.state" value="" placeholder="FL" class="form-control">
                                </div>
                            </div> -->
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="form-label" for="zip">Postal Code <sup class="text-danger" v-if="props_fields.contact.zip.required">*</sup></label>
                                    <input type="text" id="zip" v-model="UpdateProfile.zip" value="" :maxlength="getSiteProps('general.zip_code_length')" placeholder="" class="form-control">
                                    <span class="text-sm text-danger">{{ errors.zip }}</span>
                                </div>
                            </div>
                          <div class="col-md-4" v-if="props_fields.contact.citizenship">
                              <div class="form-group">
                                <label class="form-label" for="zip">Citizenship <sup class="text-danger" v-if="props_fields.contact.citizenship.required">*</sup></label>
                                <select class="form-control" id="citizenship" v-model="UpdateProfile.citizenship" name="UpdateProfile.country">
                                  <option value="">Select</option>
                                  <option :value="listOfCountry.country" v-for="listOfCountry in listOfCountries" :key="listOfCountry.country" :selected="listOfCountry.country == 'Bahamas'">{{ listOfCountry.country }}</option>
                                </select>
                                <span class="text-sm text-danger">{{ errors.citizenship }}</span>
                              </div>
                            </div>
                        </div>
                        <template v-if="UpdateProfile.address_type == 'Work'">
                            <div class="row">
                                <div :class="[props_fields.contact.work_address_company_name.colClass]" v-if="props_fields.contact.work_address_company_name">
                                    <div class="form-group">
                                        <label for="work_address_company_name" class="form-label">{{ props_fields.contact.work_address_company_name.label }}<sup class="text-danger" v-if="props_fields.contact.work_address_company_name.required">*</sup></label>
                                        <input type="text" class="form-control" id="work_address_company_name" v-model="UpdateProfile.company_name">
                                        <span class="text-sm text-danger">{{ errors.company_name }}</span>
                                    </div>
                                </div>
                                <div :class="[props_fields.contact.work_address_company_phone_extension.colClass]" v-if="props_fields.contact.work_address_company_phone_extension">
                                    <div class="form-group">
                                        <label for="work_address_company_phone" class="form-label">{{ props_fields.contact.work_address_company_phone_extension.label }}<sup class="text-danger" v-if="props_fields.contact.work_address_company_phone_extension.required">*</sup></label>
                                        <div class="row">
                                            <div class="col-md-9">
                                                <vue-tel-input ref="phone" @validate="validateTelinput" v-model="UpdateProfile.company_phone" v-bind="companyPhoneExtProps" :validCharactersOnly="true"></vue-tel-input>
                                                <span class="text-sm text-danger">{{ errors.company_phone }}</span>
                                            </div>
                                            <div class="col-md-3">
                                                <input type="text" class="form-control" v-model="UpdateProfile.company_phone_extension" name="UpdateProfile.company_phone_extension" placeholder="Extension" style="background: #F7F7F7;padding: 19px 12px;border-color: #bbb;">
                                                <span class="text-sm text-danger">{{ errors.company_phone_extension }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <div :class="[props_fields.contact.parent_citizenship.colClass]" v-if="isChild && props_fields.contact.parent_citizenship">
                          <div class="form-group">
                            <label for="parent_citizenship" class="form-label">{{ props_fields.contact.parent_citizenship.label }} <sup class="text-danger" v-if="props_fields.contact.parent_citizenship.required">*</sup></label>
                            <div class="form-group" v-if="props_fields.contact.parent_citizenship.choices">
                              <template v-for="(choice, key) in props_fields.contact.parent_citizenship.choices">
                                <input type="radio" :id="choice.value" v-model="UpdateProfile.is_parent_citizenship" name="UpdateProfile.parent_citizenship" :value="choice.value" @click="parentCitizenship = choice.value" :key="'radio_' + key">
                                <label class="parent_citizenship-label-span mr-3" :for="choice.value" :key="'parent_citizenship_label_'+key">{{ choice.label }}</label>
                              </template>
                              <span class="text-sm text-danger">{{ errors.is_parent_citizenship}}</span>
                            </div>
                          </div>
                        </div>
                        <div :class="[props_fields.contact.parent_citizenship.yes_fields.citizenship.colClass]" v-if="isChild && props_fields.contact.parent_citizenship && props_fields.contact.parent_citizenship.yes_fields.citizenship && parentCitizenship == 1">
                          <div class="form-group">
                            <label for="citizenship" class="form-label" v-if="props_fields.contact.parent_citizenship.yes_fields.citizenship.label !=''">{{ props_fields.contact.parent_citizenship.yes_fields.citizenship.label }} <sup class="text-danger" v-if="props_fields.parent_citizenship.yes_fields.citizenship.required">*</sup></label>
                            <select class="form-control" id="parent_citizenship" v-model="UpdateProfile.parent_citizenship" name="UpdateProfile.parent_citizenship">
                              <option value="">Select citizenship</option>
                              <option :value="listOfCountry.country" v-for="listOfCountry in listOfCountries" :key="listOfCountry.country" :selected="listOfCountry.country == 'Bahamas'">{{ listOfCountry.country }}</option>
                            </select>
                            <span class="text-sm text-danger">{{ errors.parent_citizenship}}</span>
                          </div>
                        </div>
                        <div :class="[props_fields.contact.athlete_citizenship.colClass]" v-if="isChild && props_fields.contact.athlete_citizenship">
                          <div class="form-group">
                            <label for="athlete_citizenship" class="form-label">{{ props_fields.contact.athlete_citizenship.label }} <sup class="text-danger" v-if="props_fields.contact.athlete_citizenship.required">*</sup></label>
                            <div class="form-group" v-if="props_fields.contact.athlete_citizenship.choices">
                              <template v-for="(choice, key) in props_fields.contact.athlete_citizenship.choices">
                                <input type="radio" :id="choice.value" v-model="UpdateProfile.is_athlete_citizenship" name="UpdateProfile.athlete_citizenship" :value="choice.value" @click="athleteCitizenship = choice.value" :key="'radio_' + key">
                                <label class="athlete_citizenship-label-span mr-3" :for="choice.value" :key="'athlete_citizenship_label_'+key">{{ choice.label }}</label>
                              </template>
                              <span class="text-sm text-danger">{{ errors.is_athlete_citizenship}}</span>
                            </div>
                          </div>
                        </div>
                        <div :class="[props_fields.contact.athlete_citizenship.yes_fields.citizenship.colClass]" v-if="isChild && props_fields.contact.athlete_citizenship && props_fields.contact.athlete_citizenship.yes_fields.citizenship && athleteCitizenship == 1">
                          <div class="form-group">
                            <label for="citizenship" class="form-label" v-if="props_fields.contact.athlete_citizenship.yes_fields.citizenship.label !=''">{{ props_fields.athlete_citizenship.yes_fields.citizenship.label }} <sup class="text-danger" v-if="props_fields.contact.athlete_citizenship.yes_fields.citizenship.required">*</sup></label>
                            <select class="form-control" id="athlete_citizenship" v-model="UpdateProfile.athlete_citizenship" name="UpdateProfile.athlete_citizenship">
                              <option value="">Select citizenship</option>
                              <option :value="listOfCountry.country" v-for="listOfCountry in listOfCountries" :key="listOfCountry.country" :selected="listOfCountry.country == 'Bahamas'">{{ listOfCountry.country }}</option>
                            </select>
                            <span class="text-sm text-danger">{{ errors.athlete_citizenship}}</span>
                          </div>
                        </div>
                        <div :class="[props_fields.contact.eligible_citizenship.colClass]" v-if="isChild && props_fields.contact.eligible_citizenship" v-show="parentCitizenship == 2 || athleteCitizenship == 2">
                          <div class="form-group">
                            <label for="eligible_citizenship" class="form-label">{{ props_fields.contact.eligible_citizenship.label }} <sup class="text-danger" v-if="props_fields.contact.eligible_citizenship.required">*</sup></label>
                            <div class="form-group" v-if="props_fields.contact.eligible_citizenship.choices">
                              <template v-for="(choice, key) in props_fields.contact.eligible_citizenship.choices">
                                <input type="radio" :id="choice.value" v-model="UpdateProfile.is_eligible_citizenship" name="UpdateProfile.eligible_citizenship" :value="choice.value" @click="eligibleCitizenship = choice.value" :key="'radio_' + key">
                                <label class="eligible_citizenship-label-span mr-3" :for="choice.value" :key="'eligible_citizenship_label_'+key">{{ choice.label }}</label>
                              </template>
                              <span class="text-sm text-danger">{{ errors.is_eligible_citizenship}}</span>
                            </div>
                          </div>
                        </div>
                        <div :class="[props_fields.contact.eligible_citizenship.yes_fields.citizenship.colClass]" v-if="isChild && props_fields.contact.eligible_citizenship && props_fields.contact.eligible_citizenship.yes_fields.citizenship && eligibleCitizenship == 1 && (parentCitizenship == 2 || athleteCitizenship == 2)">
                          <div class="form-group">
                            <label for="citizenship" class="form-label" v-if="props_fields.contact.eligible_citizenship.yes_fields.citizenship.label !=''">{{ props_fields.contact.eligible_citizenship.yes_fields.citizenship.label }} <sup class="text-danger" v-if="props_fields.contact.eligible_citizenship.yes_fields.citizenship.required">*</sup></label>
                            <select class="form-control" id="eligible_citizenship" v-model="UpdateProfile.eligible_citizenship" name="UpdateProfile.eligible_citizenship">
                              <option value="">Select citizenship</option>
                              <option :value="listOfCountry.country" v-for="listOfCountry in listOfCountries" :key="listOfCountry.country" :selected="listOfCountry.country == 'Bahamas'">{{ listOfCountry.country }}</option>
                            </select>
                            <span class="text-sm text-danger">{{ errors.eligible_citizenship}}</span>
                          </div>
                        </div>
                    </form>
                </div>
            </b-modal>
        </div>
        <div class="contact-modal">
            <b-modal id="modal-center3" size="lg" ok-title="Update" @ok="updateContactInfo" centered title="Contact Information">
                <div class="form-body">
                    <form>
                        <div class="row">
                          <div class="col-md-6" v-if="!digitalInfoView && profileData.info.membership.id != 5">
                            <div class="form-group">
                              <label class="form-label" for="parent_first_name">Parent First Name<sup class="text-danger">*</sup></label>
                              <input type="text" v-model="UpdateProfile.parent_first_name" class="form-control" id="parent_first_name" name="verifyPersonal.parent_first_name" placeholder="" value="">
                              <span class="text-danger">{{ errors.parent_first_name }}</span>
                            </div>
                          </div>
                          <div class="col-md-6" v-if="!digitalInfoView && profileData.info.membership.id != 5">
                            <div class="form-group">
                              <label class="form-label" for="parent_middle_name">Parent Middle Name</label>
                              <input type="text" v-model="UpdateProfile.parent_middle_name" class="form-control" id="verify-parent_middle_name" name="verifyPersonal.parent_middle_name" placeholder="" value="">
                              <span class="text-danger">{{ errors.parent_middle_name }}</span>
                            </div>
                          </div>
                          <div class="col-md-6" v-if="!digitalInfoView && profileData.info.membership.id != 5">
                            <div class="form-group">
                              <label class="form-label" for="parent_last_name">Parent Last Name<sup class="text-danger">*</sup></label>
                              <input type="text" v-model="UpdateProfile.parent_last_name" class="form-control" id="verify-parent_last_name" name="verifyPersonal.parent_last_name" placeholder="" value="">
                              <span class="text-danger">{{ errors.parent_last_name }}</span>
                            </div>
                          </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="form-label" for="phone_1">{{ props_fields.contact && props_fields.contact.homephone ? props_fields.contact.homephone.label : 'Phone'}} <sup class="text-danger">*</sup></label>
                                    <!-- <input type="text" id="phone_1" v-model="UpdateProfile.phone_1" value="" placeholder="8080 Holister" class="form-control"> -->
                                    <vue-tel-input ref="phone" @validate="validateTelinput" v-model="UpdateProfile.phone_1" v-bind="homeProps" :validCharactersOnly="true"></vue-tel-input>
                                    <span class="text-danger">{{ errors.phone_1 }}</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="form-label" for="phone_2">{{ props_fields.contact && props_fields.contact.mobilephone ? props_fields.contact.mobilephone.label : 'Alternative Phone'}}</label>
                                    <vue-tel-input ref="phone" @validate="validateTelinput2" v-model="UpdateProfile.phone_2" v-bind="mobileProps" :validCharactersOnly="true"></vue-tel-input>
                                    <!-- <input type="text" id="phone_2" v-model="UpdateProfile.phone_2" value="" placeholder="Apt.400" class="form-control"> -->
                                    <span class="text-danger">{{ errors.phone_2 }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="form-label" for="email">Email <sup class="text-danger">*</sup></label>
                                    <input type="text" id="email" v-model="UpdateProfile.email" value="" placeholder="William.smith@email.com" class="form-control">
                                    <span class="text-danger">{{ errors.email }}</span>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </b-modal>
        </div>
        <div class="personalinfo-modal">
            <b-modal id="modal-center1" ref="modal-center1" size="lg" ok-title="Update"  @hidden="clearErrorMessage" @ok="updatePersonalInfo" centered :title="props_fields && props_fields.personal && props_fields.personal.tab_title ? (profileData.info.membership.name == 'Staff' )?'Coach Information':(subdomain=='shooting'&&profileData.info.membership.member_type_id==7)?'Personal Information':props_fields.personal.tab_title : 'Personal Information'">
                <div class="form-body">
                    <form>
                        <div class="row" v-if="props_fields && props_fields.personal.title">
                            <div class="col-md-5">
                                <div class="form-group">
                                    <label for="title" class="form-label">{{ props_fields.personal.title.label }}<sup class="text-danger" v-if="props_fields.personal.title.required">*</sup></label>
                                    <!-- <select name="verifyPersonal.title" class="form-control" v-model="UpdateProfile.title">
                                        <option v-for="(item, k) in props_fields.personal.title.options" :value="item" :key="'title-'+k">{{ item }}</option>
                                    </select> -->
                                    <input type="text" class="form-control" id="title" v-model="UpdateProfile.title" name="verifyPersonal.title" value="">
                                    <span class="text-sm text-danger">{{ errors.title}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-5">
                                <div class="form-group">
                                    <label class="form-label" for="first_name">First Name <sup class="text-danger">*</sup></label>
                                    <input type="text" id="first_name" v-model="UpdateProfile.first_name" value="" placeholder="John" class="form-control">
                                    <span class="text-sm text-danger">{{ errors.first_name}}</span>
                                    <span class="text-sm text-danger">{{ duplicateErrorForPersonal}}</span>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label class="form-label" for="middle_name">Middle Initial</label>
                                    <input type="text" id="middle_name" v-model="UpdateProfile.middle_name" value="" placeholder="A" maxlength="3" class="form-control">
                                </div>
                              <span class="text-sm text-danger">{{ errors.middle_name}}</span>
                            </div>
                            <div class="col-md-5">
                                <div class="form-group">
                                    <label class="form-label" for="last_name">Last Name <sup class="text-danger">*</sup></label>
                                    <input type="text" id="last_name" v-model="UpdateProfile.last_name" value="" placeholder="Doe" class="form-control">
                                    <span class="text-sm text-danger">{{ errors.last_name}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="birthday" class="form-label">{{ props_fields.personal.birthday.label || 'Date of Birth' }} <sup class="text-danger">*</sup></label>
                                    <div class="input-group birthdate">
                                        <div class="birthdaypicker">
                                            <date-pick v-model="UpdateProfile.dob" :pickTime="false" name="form.dob" :format="'MM/DD/YYYY'" :selectableYearRange="{from: 1900, to: 2021}" v-mask="'##/##/####'" :isDateDisabled="isFutureDate" placeholder="MM/DD/YYYY" :parseDate="parseDatePick">
                                            </date-pick>
                                        </div>
                                        <span class="text-sm text-danger">{{ errors.dob}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6" v-if="props_fields.personal.gender">
                              <div class="form-group" v-if="props_fields && props_fields.personal.gender">
                                    <label for="gender" class="form-label">{{ props_fields.personal.gender.label }} <sup class="text-danger" v-if="props_fields.personal.gender.required">*</sup></label>
                                    <div class="d-flex" v-if="props_fields.personal.gender.choices">
                                        <template v-for="(choice, key) in props_fields.personal.gender.choices">
                                            <div class="sexitems" :key="'wrap-'+key">
                                                <input type="radio" :id="choice.value" v-model="UpdateProfile.gender" name="form.gender" :value="choice.label" :key="'radio_' + key" @click="messageFor(choice.value)">
                                                <label class="gender-label-span" :for="choice.value" :key="'gender_label_'+key">{{ choice.label }}</label>
                                            </div>
                                        </template>
                                    </div>
                                        <span class="text-sm text-danger">{{ errors.gender}}</span>
                                    <!-- <label class="form-label" for="male">Gender <sup class="text-danger">*</sup></label><br>
                                    <div class="d-flex">
                                        <div class="sexitems">
                                            <input type="radio" v-model="UpdateProfile.gender" :checked="UpdateProfile.gender == 'Male'" id="male" name="gender" value="Male">
                                            <label for="male">Male</label>
                                        </div>
                                        <div class="sexitems">
                                            <input type="radio" v-model="UpdateProfile.gender" :checked="UpdateProfile.gender == 'Female'" id="female" name="gender" value="Female">
                                            <label for="female">Female</label>
                                        </div>
                                        <div class="sexitems">
                                            <input type="radio" v-model="UpdateProfile.gender" :checked="UpdateProfile.gender == 'Other'" id="other" name="gender" value="Other">
                                            <label for="other">Other</label>
                                        </div>
                                        <span class="text-sm text-danger">{{ errors.gender}}</span>
                                    </div> -->
                                </div>
                            </div>
                            <div class="col-md-12" v-if="props_fields && props_fields.personal.clubandteam">
                                <div class="form-group">
                                    <label for="clubandteam" class="form-label">Affiliated Clubs/Teams</label>
                                    <Select2 v-model="UpdateProfile.team_id" :options="clubOptions" :placeholder="props_fields.personal.clubandteam.placeholder"  :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}"  id="clubandteam"  />
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="digitalInfoView">
                            <div class="col-md-6" v-if="props_fields.personal.veteran_status">
                                <div class="form-group">
                                    <label for="veteran_status" class="form-label">{{ props_fields.personal.veteran_status.label }} <sup class="text-danger" v-if="props_fields.personal.veteran_status.required">*</sup></label>
                                    <div class="form-group" v-if="props_fields.personal.veteran_status.choices">
                                        <template v-for="(choice, key) in props_fields.personal.veteran_status.choices">
                                            <input type="radio" :id="choice.value" v-model="UpdateProfile.veteran_status" name="veteran_status" :value="choice.label" :key="'radio_' + key">
                                            <label class="veteran_status-label-span" :for="choice.value" :key="'veteran_status_label_'+key">{{ choice.label }}</label>
                                            <br :key="'veteran_status_br_'+key">
                                        </template>
                                        <span class="text-sm text-danger">{{ errors.veteran_status}}</span>
                                    </div>
                                </div>
                            </div>
                            <div :class="[props_fields.general.sport_discipline.first_choice.multiple ? 'col-md-12 full-width-select2' : 'col-md-6']" v-if="props_fields.general.sport_discipline.first_choice&&!(subdomain=='shooting'&&profileData.info.membership.member_type_id==7)">
                                <div class="form-group">
                                    <label for="first-choice" class="form-label">{{ props_fields.general.sport_discipline.first_choice.label }} <sup class="text-danger" v-if="props_fields.general.sport_discipline.first_choice.required">*</sup></label>
                                    <Select2 v-if="props_fields.general.sport_discipline.first_choice.multiple" v-model="UpdateProfile.first_choice" name="first_choice" id="edit-disciplines" :options="sportDisciplinesSelect2" :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}" />
                                    <select v-else class="form-control" id="first-choice" name="form.first_choice" v-model="UpdateProfile.first_choice">
                                        <option value="">Select</option>
                                        <option v-for="(discipline, index) in sportDisciplines" :value="index" :key="'types'+index">{{ discipline }}</option>
                                        <option :value="null">NP - No Preference</option>
                                    </select>
                                    <span class="text-sm text-danger">{{ errors.first_choice }}</span>
                                </div>
                            </div>
                            <div class="col-md-6" v-if="props_fields.general.sport_discipline.second_choice">
                                <div class="form-group">
                                    <label for="secondchoice" class="form-label">2nd Choice </label>
                                    <select class="form-control" id="secondchoice" name="form.second_choice" v-model="UpdateProfile.second_choice">
                                        <option value="">Select</option>
                                        <option v-for="(discipline, index) in sportDisciplines" :value="index" :key="'types'+index">{{ discipline }}</option>
                                        <option :value="null">NP - No Preference</option>
                                    </select>
                                    <span class="text-sm text-danger">{{ errors.second_choice }}</span>
                                </div>
                            </div>
                          <div class="col-md-6" v-if="props_fields.demographic.race&&(subdomain=='shooting'&&profileData.info.membership.member_type_id==7)">
                            <div class="form-group radiotext">
                              <label for="race" class="form-label"><strong>{{ props_fields.demographic.race.label || 'Race'}}</strong><sup class="text-danger">*</sup></label>
                              <div v-for="(diversity,i) in diversities" :key="i">
                                <input type="radio" :id="'diversity'+diversity.name" name="UpdateProfile.diversity_id" :value="diversity.id" v-model="UpdateProfile.diversity_id">
                                <label  :for="'diversity'+diversity.name">{{diversity.name}}</label> <br>
                              </div>
                              <span class="text-sm text-danger">{{ errors.diversity_id }}</span>
                            </div>
                          </div>
                        </div>

                        <div class="row" v-if="props_fields.general.sport_discipline.home_federation&&!(subdomain=='shooting'&&profileData.info.membership.member_type_id==7)">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="home_federation" class="form-label">Federation <sup>*</sup></label>
                                    <select class="form-control" id="home_federation" name="form.home_federation"  v-model="UpdateProfile.home_federation">
                                        <option :value="null">Select</option>
                                        <option v-for="(group, index) in groups" :key="'types'+index">{{ group.name }}</option>
                                    </select>
                                    <span class="text-sm text-danger">{{ errors.home_federation }}</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div v-if="UpdateProfile.home_federation !='USA' && UpdateProfile.home_federation != null">
                                    <div class="form-group">
                                        <label for="skier_license" class="form-label">Foreign Federation ID<sup>*</sup></label>
                                        <input type="text" id="skier_license" class="form-control" maxlength="9" placeholder="" name="form.skier_license" v-model="UpdateProfile.skier_license"  @keypress="isNumber($event)">
                                        <span class="text-sm text-danger">{{ errors.skier_license }}</span><br/>
                                        <span class="text-danger font-13">FOR FOREIGN ATHLETES Use numbers only as listed in EMS Athlete Code DO NOT INCLUDE FEDERATION PREFIX</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="props_fields.personal.clubslist && profileData.info.personal.relation===0&&!(subdomain=='shooting'&&profileData.info.membership.member_type_id==7)">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="clubslist" class="form-label">{{ props_fields.personal.clubslist.label }}<sup class="text-danger" v-if="props_fields.personal.clubslist.required">*</sup></label>
                                    <Select2 v-model="UpdateProfile.club" :options="clubOptions" name="verifyPersonal.club" :settings="{ placeholder: props_fields.personal.clubslist.placeholder}" />
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="UpdateProfile.club == '-' && profileData.info.personal.relation===0&&!(subdomain=='shooting'&&profileData.info.membership.member_type_id==7)">
                            <div class="col-md-4" v-if="props_fields.personal.new_club_name">
                                <div class="form-group">
                                    <label for="new_club_name" class="form-label">New Club Name<sup class="text-danger" v-if="props_fields.personal.new_club_name.required">*</sup></label>
                                    <input type="text" v-model="UpdateProfile.new_club_name" class="form-control" id="verify-new_club_name" name="verifyPersonal.new_club_name" placeholder="" value="">
                                    <span class="text-sm text-danger">{{ errors.new_club_name }}</span>
                                </div>
                            </div>
                            <div class="col-md-4" v-if="props_fields.personal.new_club_email">
                                <div class="form-group">
                                    <label for="new_club_email" class="form-label">New Club E-Mail<sup class="text-danger" v-if="props_fields.personal.new_club_email.required">*</sup></label>
                                    <input type="text" v-model="UpdateProfile.new_club_email" class="form-control" id="verify-new_club_email" name="verifyPersonal.new_club_email" placeholder="">
                                    <span class="text-sm text-danger">{{ errors.new_club_email }}</span>
                                </div>
                            </div>
                            <div class="col-md-4" v-if="props_fields.personal.new_club_phone">
                                <div class="form-group">
                                    <label for="new_club_phone" class="form-label">New Club Phone<sup class="text-danger" v-if="props_fields.personal.new_club_phone.required">*</sup></label>
                                    <vue-tel-input v-model="UpdateProfile.new_club_phone" ref="phone" @validate="validateTelinput"  v-bind="mobileProps" :validCharactersOnly="true"></vue-tel-input>
                                    <span class="text-sm text-danger">{{ errors.new_club_phone }}</span>
                                </div>
                            </div>
                        </div>
                      <div class="row" v-if="!digitalInfoView && isChild">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label for="agl_player_id" class="form-label">
                              AGL Player ID#
                            </label>
                            <input type="text" class="form-control" maxlength="45" id="agl_player_id" v-model="UpdateProfile.agl_player_id" name="form.agl_player_id" value="">
                            <span class="text-sm text-danger">{{ errors.agl_player_id}}</span>
                          </div>
                        </div>
                        <div :class="[props_fields.personal.graduation_year.colClass]" v-if="props_fields.personal.graduation_year">
                          <div class="form-group">
                            <label for="graduation_year" class="form-label">
                              {{ props_fields.personal.graduation_year.label }}<sup class="text-danger" v-if="props_fields.personal.graduation_year.required">*</sup>
                            </label>
                            <select class="form-select form-control" id="graduation_year" required="" name="form.graduation_year" v-model="UpdateProfile.graduation_year">
                              <option value="">Select Graduation Year</option>
                              <option v-for="year in GraduationyearArr" :value="year" :key="year">{{ year }}</option>
                            </select>
                            <span class="text-sm text-danger">{{ errors.graduation_year}}</span>
                          </div>
                        </div>
                        <div :class="[props_fields.personal.jersey_number.colClass]" v-if="props_fields.personal.jersey_number">
                          <div class="form-group">
                            <label for="jersey_number" class="form-label">
                              {{ props_fields.personal.jersey_number.label }}<sup class="text-danger" v-if="props_fields.personal.jersey_number.required">*</sup>
                            </label>
                            <input class="form-control" :placeholder="props_fields.personal.jersey_number.placeholder" maxlength="3" id="jersey_number" v-model="UpdateProfile.jersey_number" name="form.jersey_number" value="">
                            <span class="text-sm text-danger">{{ errors.jersey_number}}</span>
                          </div>
                        </div>
                        <div :class="[props_fields.personal.primary_position.colClass]" v-if=" props_fields.personal.primary_position">
                          <div class="form-group">
                            <label for="primary_position" class="form-label">{{ props_fields.personal.primary_position.label }} <sup class="text-danger" v-if="props_fields.personal.primary_position.required">*</sup></label>
                            <select class="form-control" id="primary_position" v-model="UpdateProfile.primary_position" name="form.primary_position">
                              <option value="">Select</option>
                              <option  v-for="(listOfPrimaryPosition,key) in props_fields.personal.primary_position.options" :key="key" :value="listOfPrimaryPosition">{{ listOfPrimaryPosition }}</option>
                            </select>
                            <span class="text-sm text-danger">{{ errors.primary_position}}</span>
                          </div>
                        </div>
                        <div :class="[props_fields.personal.secondary_position.colClass]" v-if="props_fields.personal.secondary_position">
                          <div class="form-group">
                            <label for="secondary_position" class="form-label">{{ props_fields.personal.secondary_position.label }} <sup class="text-danger" v-if="props_fields.personal.secondary_position.required">*</sup></label>
                            <select class="form-control" id="secondary_position" v-model="UpdateProfile.secondary_position" name="form.secondary_position">
                              <option value="">Select</option>
                              <option  v-for="(listOfSecondaryPosition,key) in props_fields.personal.secondary_position.options" :key="key" :value="listOfSecondaryPosition">{{ listOfSecondaryPosition }}</option>
                            </select>
                            <span class="text-sm text-danger">{{ errors.secondary_position}}</span>
                          </div>
                        </div>
                        <div class="col-md-3" >
                          <div class="form-group">
                            <label for="throw" class="form-label">{{ props_fields.personal.throw.label }} <sup class="text-danger" v-if="props_fields.personal.throw.required">*</sup></label>
                            <div class="form-group" v-if="props_fields.personal.throw.choices">
                              <template v-for="(choice, key) in props_fields.personal.throw.choices">
                                <input type="radio" :id="choice.value" v-model="UpdateProfile.throws" name="form.throw" :value="choice.value" :key="'radio_' + key">
                                <label class="throw-label-span mr-3" :for="choice.value" :key="'throw_label_'+key">{{ choice.label }}</label>
                              </template>
                              <span class="text-sm text-danger">{{ errors.throw}}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3" >
                          <div class="form-group" >
                            <label for="bats" class="form-label">{{ props_fields.personal.bats.label }} <sup class="text-danger" v-if="props_fields.personal.bats.required">*</sup></label>
                            <div class="form-group" v-if="props_fields.personal.bats.choices">
                              <template v-for="(choice, key) in props_fields.personal.bats.choices">
                                <input type="radio" :id="choice.value" v-model="UpdateProfile.bats" name="form.bats" :value="choice.value" :key="'radio_' + key">
                                <label class="bats-label-span mr-3" :for="choice.value" :key="'bats_label_'+key">{{ choice.label }}</label>
                              </template>
                              <span class="text-sm text-danger">{{ errors.bats}}</span>
                            </div>
                          </div>
                        </div>
                        <div :class="[props_fields.personal.college_committed.colClass]" >
                          <div class="form-group">
                            <label for="college_committed" class="form-label">{{ props_fields.personal.college_committed.label }} <sup class="text-danger" v-if="props_fields.personal.college_committed.required">*</sup></label>
                            <div class="form-group" v-if="props_fields.personal.college_committed.choices">
                              <template v-for="(choice, key) in props_fields.personal.college_committed.choices">
                                <input type="radio" :id="choice.value" v-model="college_list" name="form.college_committed" :value="choice.value" @click="collegeCommitted = choice.value" :key="'radio_' + key">
                                <label class="college_committed-label-span mr-3" :for="choice.value" :key="'college_committed_label_'+key">{{ choice.label }}</label>
                              </template>
                              <span class="text-sm text-danger">{{ errors.college_committed}}</span>
                            </div>
                          </div>
                        </div>
                        <div :class="[props_fields.personal.college_committed.yes_fields.college_list.colClass]" v-if="props_fields.personal.college_committed.yes_fields.college_list && collegeCommitted == 1">
                          <div class="form-group">
                            <label for="college_list" class="form-label" v-if="props_fields.personal.college_committed.yes_fields.college_list.label !=''">{{ props_fields.personal.college_committed.yes_fields.college_list.label }} <sup class="text-danger" v-if="props_fields.personal.college_committed.yes_fields.college_list.required">*</sup></label>
                            <Select2 id="college_list" v-model="UpdateProfile.college_committed" :options="collegeOptions" name="form.college_list" :settings="{ placeholder: 'Click to Select the College'}" />
                          </div>
                        </div>
                      </div>
                    </form>
                </div>
            </b-modal>
        </div>
        <b-modal id="profile_pic_modal" size="md" ref="modal"  ok-title="Upload photo" centered title="Photo"
                 @show="resetModal" @hidden="resetModal" @ok="updateProfilePic">
            <div class="form-body">
                <b-form-file v-model="uploadPicFile" :state="Boolean(uploadPicFile)" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." v-on:change="onImageChange" accept=".jpg, .png, .gif"></b-form-file>
                <div class="mt-3">Selected file: <img :src="getPreview()" alt="aa" style="height:177px;width:177px;" /></div>
            </div>
        </b-modal>
        <!-- <div class="dashboard-qustion pt-0">
            <p class="footer-quest pb-3" v-html="this.getSiteProps('footertop.question')"></p>
        </div> -->
        <b-modal id="edit-demographic" size="lg" ok-title="Update" @ok="updateDemographicInfo" centered :title="props_fields.demographic.tab_title || 'Demographic Details'">
            <div class="form-body">
                <form>
                    <div class="row">
                        <div class="col-md-6" v-if="props_fields.demographic.race">
                            <div class="form-group radiotext">
                                <label for="race" class="form-label"><strong>{{ props_fields.demographic.race.label || 'Race'}}</strong><sup class="text-danger">*</sup></label> 
                                <div v-for="(diversity,i) in diversities" :key="i">
                                    <input type="radio" :id="'diversity'+diversity.name" name="UpdateProfile.diversity_id" :value="diversity.id" v-model="UpdateProfile.diversity_id">
                                    <label  :for="'diversity'+diversity.name">{{diversity.name}}</label> <br>
                                </div>
                                    <span class="text-sm text-danger">{{ errors.diversity_id }}</span>
                            </div>
                        </div>
                        <div class="col-md-6" v-if="props_fields.demographic.ethnicity">
                            <div class="form-group radiotext ">
                                <label for="ethnicitystatus" class="form-label"><strong>Ethnicity</strong><sup  class="text-danger">*</sup></label>
                                <div class="form-group">
                                    <label for="hispanic-or-latino"><input type="radio" id="hispanic-or-latino" name="ethnicity" value="Hispanic or Latino" v-model="UpdateProfile.ethnicity"> Hispanic or Latino</label><br>
                                    <label for="non-hispanic-or-latino"> <input type="radio" id="non-hispanic-or-latino" name="ethnicity" value="Non-Hispanic or Latino" v-model="UpdateProfile.ethnicity"> Non-Hispanic or Latino</label><br>
                                    <label for="ethnicity-Prefer-not-to-say"> <input type="radio" id="ethnicity-Prefer-not-to-say" name="ethnicity" value="Prefer not to say" v-model="UpdateProfile.ethnicity"> Prefer not to say</label><br>
                                    <span class="text-sm text-danger">{{ errors.ethnicity }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6" v-if="props_fields.demographic.disability_status">
                            <div class="form-group radiotext ">
                                <label for="disabilitystatus" class="form-label"><strong>Disability Status</strong>  <sup  class="text-danger">*</sup></label>
                                <div class="form-group">
                                    <label for="normal"> <input type="radio" id="normal" name="verifyPersonal.disability" value="Persons without Disability" v-model="UpdateProfile.disability"> Persons without Disability</label><br>
                                    <label for="Persons-with-Disability"><input type="radio" id="Persons-with-Disability" name="verifyPersonal.disability" value="Persons with Disability" v-model="UpdateProfile.disability"> Persons with Disability</label><br>
                                    <label for="disability-Prefer-not-to-say"><input type="radio" id="disability-Prefer-not-to-say" name="verifyPersonal.disability" value="Prefer not to say" v-model="UpdateProfile.disability"> Prefer not to say</label><br>
                                    <span class="text-sm text-danger">{{ errors.disability }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6" v-if="props_fields.demographic.veteran_status">
                            <div class="form-group radiotext ">
                                <label for="veteranstatus" class="form-label"><strong>Veteran Status</strong> <sup class="text-danger">*</sup></label>
                                <div class="form-group">
                                    <div>
                                        <label for="veteran">
                                            <input type="radio" id="veteran" name="verifyPersonal.veteran_status" value="Veteran" v-model="UpdateProfile.veteran_status"> Veteran
                                        </label>
                                        <br>
                                    </div>
                                    <div>
                                        <label for="non-Veteran">
                                            <input type="radio" id="non-Veteran" name="verifyPersonal.veteran_status" value="Non-Veteran" v-model="UpdateProfile.veteran_status"> Non-Veteran
                                        </label>
                                        <br>
                                    </div>
                                    <div>
                                        <label for="Active-or-Reserve">
                                            <input type="radio" id="Active-or-Reserve" name="verifyPersonal.veteran_status" value="Active or Reserve" v-model="UpdateProfile.veteran_status"> Active or Reserve
                                        </label>
                                        <label for="vet-prefer-not-to-say">
                                            <input type="radio" id="vet-prefer-not-to-say" name="verifyPersonal.veteran_status" value="Prefer not to say" v-model="UpdateProfile.veteran_status"> Prefer not to say
                                        </label>
                                        <br>
                                    </div>
                                    <span class="text-sm text-danger">{{ errors.veteran_status }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6" v-if="props_fields.demographic.primary_area_interest">
                                <div class="form-group">
                                    <label for="area_of_interest" class="form-label">
                                        {{ props_fields.demographic.primary_area_interest.label }}
                                        <sup class="text-danger" v-if="props_fields.demographic.primary_area_interest.required">*</sup>
                                    </label>
                                    <select name="verifyPersonal.area_of_interest" id="verify-area_of_interest" class="form-control" v-model="UpdateProfile.primary_area_interest">
                                        <option value="">Select</option>
                                        <option v-for="(item, k) in props_fields.demographic.primary_area_interest.options" :key="'v-pai'+k" :value="item">{{ item }}</option>
                                    </select>
                                    <span class="text-sm text-danger">{{ errors.primary_area_interest }}</span>
                                </div>
                            </div>
                            <div class="col-md-6" v-if="props_fields.demographic.category">
                                <div class="form-group">
                                    <label for="category" class="form-label">{{ props_fields.demographic.category.label }}<sup class="text-danger" v-if="props_fields.demographic.category.required">*</sup></label>
                                    <select name="verifyPersonal.category" id="verify-category" class="form-control" v-model="UpdateProfile.category">
                                        <option value="">Select</option>
                                        <option v-for="(item, k) in categories" :key="'verify-catd-'+k" :value="item.id">{{ item.name }}</option>
                                    </select>
                                    <span class="text-sm text-danger">{{ errors.category }}</span>
                        </div>
                            </div>
                            <div class="col-md-6" v-if="props_fields.demographic.issf">
                                <div class="form-group">
                                    <label for="issf" class="form-label">{{ props_fields.demographic.issf.label }}<sup class="text-danger" v-if="props_fields.demographic.issf.required">*</sup></label>
                                    <input type="text" class="form-control" name="verifyPersonal.issf" v-model="UpdateProfile.issf">
                                    <span class="text-sm text-danger">{{ errors.issf }}</span>
                                </div>
                            </div>
                            <div class="col-md-6" v-if="props_fields.demographic.cmp">
                                <div class="form-group">
                                    <label for="cmp_no" class="form-label">{{ props_fields.demographic.cmp.label }}<sup class="text-danger" v-if="props_fields.demographic.cmp.required">*</sup></label>
                                    <input type="text" class="form-control" name="verifyPersonal.cmp_no" v-model="UpdateProfile.cmp_no">
                                    <span class="text-sm text-danger">{{ errors.cmp_no }}</span>
                                </div>
                            </div>
                            <div class="col-md-6" v-if="props_fields.demographic.nra">
                                <div class="form-group">
                                    <label for="nra_no" class="form-label">{{ props_fields.demographic.nra.label }}<sup class="text-danger" v-if="props_fields.demographic.nra.required">*</sup></label>
                                    <input type="text" class="form-control" name="verifyPersonal.nra_no" v-model="UpdateProfile.nra_no">
                                    <span class="text-sm text-danger">{{ errors.nra_no }}</span>
                                </div>
                            </div>
                            <div class="col-md-6" v-if="props_fields.demographic.sasp">
                                <div class="form-group">
                                    <label for="sasp_no" class="form-label">{{ props_fields.demographic.sasp.label }}<sup class="text-danger" v-if="props_fields.demographic.sasp.required">*</sup></label>
                                    <input type="text" class="form-control" name="verifyPersonal.sasp_no" v-model="UpdateProfile.sasp_no">
                                    <span class="text-sm text-danger">{{ errors.sasp_no }}</span>
                                </div>
                            </div>
                            <div class="col-md-6" v-if="props_fields.demographic.sctp">
                                <div class="form-group">
                                    <label for="sctp_no" class="form-label">{{ props_fields.demographic.sctp.label }}<sup class="text-danger" v-if="props_fields.demographic.sctp.required">*</sup></label>
                                    <input type="text" class="form-control" name="verifyPersonal.sctp_no" v-model="UpdateProfile.sctp_no">
                                    <span class="text-sm text-danger">{{ errors.sctp_no }}</span>
                                </div>
                            </div>
                            <div class="col-md-6" v-if="props_fields.demographic.hear_about">
                                <div class="form-group">
                                    <label for="hear_about" class="form-label">{{ props_fields.demographic.hear_about.label }} <sup class="text-danger" v-if="props_fields.demographic.hear_about.required">*</sup></label>
                                    <select id="verify_hear_about" class="form-control" name="verifyPersonal.hear_about" v-model="UpdateProfile.hear_about">
                                        <option value="">Select</option>
                                        <option v-for="(item, k) in props_fields.demographic.hear_about.options" :key="'verify-hear-about-'+k" :value="item">{{ item }}</option>
                                    </select>
                                    <span class="text-sm text-danger">{{ errors.hear_about }}</span>
                                </div>
                            </div>
                        </div>
                    </div>    
                </form>
            </div>
        </b-modal>
        <!--Magazine-->
        <b-modal  tabindex="-1" hide-footer id="edit-magazine" title="Magazine Subscription" ref="my-modal"  :no-close-on-backdrop="true">
            <div class="form-body">
                <form>
                    <div class="row">
                      <div class="col-md-12" v-if="enableMagazine" v-show="digitalView">
                                   <b-form-checkbox v-model="subscription.printed_magazine" name="check-button" switch @change="changeMagazineSubscription(profileData.info.printed_magazine)" value="0" unchecked-value="1">
                                      Receive a printed copy of the Water Skier magazine
                                    </b-form-checkbox>
                        </div> 
                    </div>
                </form>
            </div>
        </b-modal>
    </div>
</template>

<script>
const validateTitle = title => {
    if (title == null || title == "" || title.length == 0) {
        return { valid: false, error: "This field is required" };
    } 
    return { valid: true, error: null };
};

const validateName = (name, fieldName = '') => {
    let names = '';
    if(name != null){
      names = name.trim();
    }
  let fieldText = ( fieldName != '' ? fieldName : "This field");

  if (!names.length) {
    return { valid: false, error: fieldText+ " is required" };
  }
  if (!names.match(/^[a-zA-Z\s]*$/))
  {
    return { valid: false, error:  fieldText+ " must be letters only. No other characters" };
  }
  return { valid: true, error: null };
};
const validateLastName = (name, fieldName = '') => {
  let names = '';
  if(name != null){
    names = name.trim();
  }
  let fieldText = ( fieldName != '' ? fieldName : "This field");

  if (!names.length) {
    return { valid: false, error: fieldText+ " is required" };
  }
  if (!names.match(/^[a-zA-Z-\s]*$/))
  {
    return { valid: false, error:  fieldText+ " must be letters and hyphens only. No other characters" };
  }
  return { valid: true, error: null };
};
const validateDob = (dob, membership_id, age, isNotAlliance, current_age_division, age_divisionArr, isPlayer) => {
        if (dob == null || dob.length == 0) {
            return { valid: false, error: "This field is required" };
        }
        /*if (!dob.match(/^\w+([.-]?\w+)_@\w+(_[_.-]?\w+)_(.\w{2,3})+$/)) {
        return { valid: false, error: "Please, enter a valid data of birth." };
        }*/
        // validation for alliance staff dob
        if(!isNotAlliance && membership_id ==5 && age < 18){
          return { valid: false, error: "Coach user age should be age 18 or above." };
        }

      if (!isNotAlliance && isPlayer) {
        var valObj = age_divisionArr.filter(function (elem) {
          if (elem.value == current_age_division) return elem.value;
        });
        if (valObj.length > 0) {
          age = Math.floor(age);
          const division = valObj[0].validAge;
          if (age > division) {
            return {valid: false, error: "DOB must be under the age of " + division};
          }
        }
      }

        return { valid: true, error: null };
    };
    const validateGender = gender => {
        if (!gender) {
            return { valid: false, error: "This field is required" };
        }
        return { valid: true, error: null };
    };
    const validateSelect = (select, fieldState='',isNull=true) => {
        console.log('m select');
        console.log(select);
        let textState = (fieldState != '' ? fieldState : "This field");
        if (select == "" || (select == null && isNull)) {
            return {valid: false, error: textState + " is required"};
        }
        return { valid: true, error: null };
    };
    const validateSelectOption = (select, fieldState='') => {
        console.log('m select');
        console.log(select);
        let textState = (fieldState != '' ? fieldState : "This field");
        if(!Number.isInteger(select)){
          if(select == "" || select == null || !select.length){
            return {valid: false, error: textState + " is required"};
          }
        }
        if (select == "" || select == null) {
            return {valid: false, error: textState + " is required"};
        }
        return { valid: true, error: null };
    };
    const validateRadio = (radio, fieldName='') => {
      let fieldText= (fieldName != '' ? fieldName : "This field");
      if (!radio) {
        return {valid: false, error: fieldText+" is required"};
      }
      return {valid: true, error: null};
    };
    const validateStateName = name => {
        console.log('v state');
        console.log(name);
        if (name == null || name == "") {
            return { valid: false, error: "This field is required" };
        }
        return { valid: true, error: null };
    };
    const validateEmail = (email, fieldEmail='') => {
  let emailText= (fieldEmail != '' ? fieldEmail : "This field");
  if (email == null || email == '' ) {
    return { valid: false, error: emailText+" is required" };
  }
        if (!email.match(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/) || !email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)) {
        return {valid: false, error: "Please enter a valid email."};
    }
  return { valid: true, error: null };
};

    const validateZip = (zipCode, country) => {
        if(zipCode == null || !zipCode.length)
        {
            return { valid: false, error: "Zip code is required" };
        }
        else if(!zipCode.match(/^\d{5}(-\d{4})?$/) && country == 'USA'){
            return { valid: false, error: "Zip Code Should Be 5 Numbers for USA." };
        }
        else if(zipCode.length < 5 ){
            return { valid: false, error: "Zip code should be 5 character" };
        }
        return { valid: true, error: null };
    };
    const validateFedration = name => {
      if (!name || name.trim() == "") {
        return {valid: false, error: "This field is required"};
      } else if (isNaN(name.trim())) {
        return {valid: false, error: "This field must be a numeric value"};
      }
    //   else if (name.length != 9 || name.trim() == "") {
    //     return {valid: false, error: "Foreign federation ID must be 9 characters"};
    //   }
      return {valid: true, error: null};
    };
  const validatePhone = (checkValTelInput, phone, validTelinput, phoneField= '') => {
    let phoneText = ( phoneField != '' ? phoneField : "This field");
    if (phone == '' || phone == null) {
      return { valid: false, error: phoneText+' is required .' };
    }

    if(checkValTelInput){
    if(validTelinput == false && phone != '' && phone != null){
      return { valid: false, error: 'Enter the Exact phone number(Example: +XX XX XXXX XXXX).' };
    }
    }

    return { valid: true, error: null };
  };
  const validateAddressName = (name, contactField='' ) => {
  let contactText = ( contactField != '' ? contactField : "This field");
  if (name == null || !name.length) {
    return { valid: false, error: contactText+ " is required " };
  }
  return { valid: true, error: null };
};
const validateOptionalTextFields = (name, fieldName = '') => {
  let names = '';
  if(name != null){
    names = name.trim();
  }
  let fieldText = ( fieldName != '' ? fieldName : "This field");

  if (names != null && names.length && !names.match(/^[a-zA-Z\s]*$/))
  {
    return { valid: false, error:  fieldText+ " must be letters only. No other characters" };
  }
  return { valid: true, error: null };
};
const validatePhone2 = (checkValTelInput, phone, validTelinput, phoneField= '') => {
  if(checkValTelInput){
    if(validTelinput == false && phone != '' && phone != null){
      return { valid: false, error: 'Enter the Exact phone number(Example: +XX XX XXXX XXXX).' };
    }
  }
  return { valid: true, error: null };
};
const validateJerseyNumber = number => {
  if(!number){
    return {valid: false,error:"This field is required"};
  }
  if(!number.match(/^[0-9]+$/)){
    return { valid: false, error: "Please enter a valid Jersey Number." };
  }
  return {valid: true, error: null};
};



    // import navigation from "../components/Navigation";
    // import sidebar from "../components/Sidebar";
    // import dashboardfooter from "../components/Footer";
    import AutoRenewalButton from "../../../admin/components/AutoRenewalButton";
    import CustomAlert from "../../../CustomAlert";
    import membercard from "./membercard";
    import moment from "moment";
    import axios from 'axios';
    import DatePick from 'vue-date-pick';
    import { EventBus } from '@/event-bus';
    import $ from 'jquery';

    export default {
    name: "myprofile",
    components: {CustomAlert, membercard, AutoRenewalButton, DatePick/*, Select2*/},
    data() {
        return {
          duplicateErrorForPersonal:'',
          safeSportApiCall: false,
          TempProfileData:'',
            age: 0,
            displayMissingMemFields: false,
            profileData: {
                info:[],
                SafeSport: {
                    ActiveStatus: ''
                },
                BGScreening: {
                    BackgroundReportPackage: {
                        ScreeningStatus: {
                            OrderStatus: ''
                        }
                    }
                }
            },
          UpdateProfile:{
            "title": "",
            "veteran_status": "",
            "first_name": "",
            "last_name": "",
            "middle_name": "",
            "suffix": "",
            "address_type": "",
            "address1": "",
            "address2": "",
            "zip": "",
            "state": "",
            "state_code": "",
            "city": "",
            "email": "",
            "country": "",
            "phone_1": "",
            "phone_2": "",
            "company_name": "",
            "company_phone": "",
            "company_phone_extension": "",
            "dob": "",
            "gender": "",
            "citizenship":"",
            "team_id":[],
            "first_choice":"",
            "second_choice":"",
            "home_federation":"",
            "skier_license":"",
            "membership_status":"",
            "safesports_status":"",
            "registration_id":"",
            "background_screen":"",
            "diversity_id": "",
            "ethnicity": "",
            "disability": "",
            "primary_area_interest": "",
            "category": "",
            "issf": "",
            "cmp_no": "",
            "nra_no": "",
            "sasp_no": "",
            "sctp_no": "",
            "hear_about": "",
            "club": "",
            "affiliate":[],
            "new_club_name": "",
            "new_club_email": "",
            "new_club_phone": "",
            "printed_magazine":"",
            "is_parent_citizenship": 0,
            "is_athlete_citizenship": 0,
            "is_eligible_citizenship": 0,
            "parent_citizenship": "",
            "athlete_citizenship": "",
            "eligible_citizenship": "",
            "parent_first_name": "",
            "parent_last_name": "",
            "parent_middle_name": ""
          },
            alertMessage: "",
            displayAlert: "",
            profileImg:'/dist/img/profile1.png',
            uploadPicFile:'',
            profilePic:'',
            preview:'',
            changeMagazine:[],
            autoRenew:0,
            autoRenewData:{
                regId:"",
                status:"",
            },
            props_fields: null,
          isExpired:"",
          startDate: '',
          validThru: '',

          validTelinput:false,
          homeProps: {
            mode: "international",
            defaultCountry: "US",
            inputOptions:{
              autocomplete:"on",
              autofocus:false,
              id:"",
              maxlength:20,
              name:"telephone",
              placeholder:"Home Number",
              readonly:false,
              required:false,
              showDialCode:false,
              styleClasses:"",
              tabindex:0,
              type:"tel"
            }

          },
          mobileProps: {
            mode: "international",
            defaultCountry: "US",
            inputOptions:{
              autocomplete:"on",
              autofocus:false,
              id:"",
              maxlength:20,
              name:"telephone",
              placeholder:"Mobile Number",
              readonly:false,
              required:false,
              showDialCode:false,
              styleClasses:"",
              tabindex:0,
              type:"tel"
            }
          },
          companyPhoneExtProps: {
            mode: "international",
            defaultCountry: "US",
            placeholder: "Company Phone Extension",
            // maxLen: 30,
          },
          validTelinput2:false,
          diversities: [],
          categories: [],
          valid: false,
          props_membership: "",
          safeSportRefreshButton: 'Refresh',
          props_tooltips: "",
           checked1: true,
        checked2: false,
          refreshProgress: false,
          stripeToken: null,
            stripeValidate: false,
            clubOptions:[],
            digitalView:true,
            memberGroups: [],
            memberGroupVisible: false,
            subscription: {
                id:"",
                printed_magazine:""
            },
            digitalInfoView:true,
            GraduationyearArr: [],
            isChild:false,
            collegeCommitted : null,
            page:'myprofile',
            college_list : null,
            parentCitizenship:0,
            athleteCitizenship:0,
            eligibleCitizenship:0
        }
    },
    methods:
        {
          clearErrorMessage() {
            this.duplicateErrorForPersonal = '';
          },
            autoRefreshSafeSport(){
                if(
                    this.profileData.info.safe_sport != null &&
                    (
                        [1,13].indexOf(this.profileData.info.safe_sport.safe_sport_status) !== -1 ||
                        (
                            this.profileData.info.safe_sport.safe_sport_status === 3 &&
                            moment(this.profileData.info.safe_sport.expiration, "MM/DD/YYYY").isBefore(new Date())
                        )
                    ) &&
                    this.safeSportApiCall === false
                ) {
                  this.safeSportApiCall = true;
                  this.getSafeSportUserApi();
                }
            },
            validationErrorAlert(errors){
                    let arrayVal = Object.keys(errors).map((key) => [(key), errors[key]]);
                        let message = "<ul>";
                        let date = new Date();
                        let bullet = date.getFullYear();
                        console.log('bullet', bullet);
                            arrayVal.forEach((value, index ) => {
                                if(value[1] != null){
                                    // message = message +value[0]+' : '+ value[1] +', '
                                    message = message +'<li>'+ value[1] +'</li> ';
                                }

                            });
                            message = message + ' Please update.';
                            this.setDangerBg();
                                    setImmediate(() => {
                                        this.displayAlert = true;
                                        this.alertMessage = message;
                                    }, 100);
            },
            setDangerBg(){
                setTimeout(() => {
                                    $('#alert_box').children('div').removeClass('alert-success');
                                    $('#alert_box').children('div').addClass('alert-danger');
                        }, 100);
            },
            removeDangerBg(){
                setTimeout(() => {
                                    $('#alert_box').children('div').removeClass('alert-danger');
                                    $('#alert_box').children('div').addClass('alert-success');
                        }, 100);
            },
            getSafeSportUserApi() {
                this.safeSportRefreshButton = 'Processing...';
                axios.get(this.basePath+"api/member/safeSport",{ headers:this.memberHeaders })
                    .then((res) => {
                        console.log(res.data);
                        this.profileData.SafeSport = res.data.data;
                        this.profileData.info.safe_sport.safe_sport_status_text = this.profileData.SafeSport.safe_sport_status_text;
                        this.refreshCard();
                        // this.loadMembersInfo();
                        this.safeSportRefreshButton = 'Refresh';
                        return res;
                    })
                    .catch((error) => {
                        console.log('Error:'+ error);
                        this.safeSportRefreshButton = 'Refresh';
                        return false;
                        // error.response.status Check status code
                    }).finally(() => {
                });
            },
          getBackgroundScreeningApi() {
                axios.get(this.basePath+'api/member/background_screening')
                    .then((res) => {
                        this.profileData.BGScreening = res.data.data;
                        return res;
                    })
                    .catch((error) => {
                        console.log('Error:'+ error);
                        return false;
                        // error.response.status Check status code
                    }).finally(() => {
                });
            },
            loadMembersInfo(){

                axios.get(this.basePath+"api/member/my_profile",{ headers:this.memberHeaders })
                    .then((response)=>{
                        let resp  = response.data.data;
                        this.tempProfileData = response.data.data;
                        this.age = this.getAge(resp.dob);
                                if(resp.email == null || resp.phone_1 == null){
                                     this.alertMessage = "E-Mail or Mobile number is missing.<br> Please fill update.";
                                    this.displayAlert = true;
                                    this.setDangerBg();
                                }
                        this.subscription.id =resp.id;
                                console.log('curren user id',this.subscription.id)
                        this.profileData.info = resp;

                        this.autoRenew = resp.auto_renew;
                        this.autoRenewData.regId = resp.registration[0].id;
                        this.autoRenewData.status = resp.auto_renew;

                        this.UpdateProfile.team_id = Object.keys(this.profileData.info.affiliated_clubs_dropdown);
                        for (let prop in resp) {
                            if ( Object.prototype.hasOwnProperty.call(this.UpdateProfile, prop) ) {
                                this.UpdateProfile[prop] = resp[prop]
                            }
                        }
                      for (let prop in resp.personal) {
                        if ( Object.prototype.hasOwnProperty.call(this.UpdateProfile, prop) ) {
                            if(prop == "company_name" && resp.personal[prop] == null) continue;
                            else
                                this.UpdateProfile[prop] = resp.personal[prop]
                        }
                      }

                      this.UpdateProfile.diversity_id = this.UpdateProfile.diversity_id == 99 ? '' : this.UpdateProfile.diversity_id;

                      this.validThru = moment(this.profileData.info.membership_endtate).format('MM/DD/YYYY');
                      this.isExpired = moment(new Date()).isAfter(moment(new Date(this.validThru)));
                      this.subscription.printed_magazine = this.profileData.info.printed_magazine;
                        console.log(this.printed_magazine);
                      this.profileImg = (resp.profile_pic!=null? process.env.VUE_APP_URL+resp.profile_pic:'/dist/img/profile1.png')+'?v='+moment().format('x');
                    if(resp.disciplines != undefined && Array.isArray(resp.disciplines)){
                        this.UpdateProfile.first_choice = [];
                        resp.disciplines.forEach(dis => {
                            this.UpdateProfile.first_choice.push(dis.id);
                        });
                    }

                     this.tempProfileData.first_choice = this.UpdateProfile.first_choice;
                      this.tempProfileData.second_choice = this.UpdateProfile.second_choice;

                      this.autoRefreshSafeSport();

                    if(resp.membership != undefined){
                        const retry = setInterval(() => {
                            let memberTypes = this.getSiteProps('membershipTypes');
                            if(memberTypes) {
                                clearInterval(retry);
                                let currentMemberType = memberTypes.filter(item => item.id == resp.membership.member_type_id);
                                if(currentMemberType.length){
                                    let memberType = currentMemberType.shift();
                                    if(memberType.memberships != undefined && memberType.memberships.length){
                                        this.props_membership = memberType.memberships.filter(item => item.id == resp.membership.id);
                                        if(this.props_membership.length) this.props_membership = this.props_membership.shift();
                                    }
                                }
                            }
                        }, 200);
                    }

                    if(this.subdomain == 'shooting' && this.UpdateProfile.club == null && resp.affiliated_clubs_dropdown != null){
                        let affiliated_clubs_dropdown_value = resp.affiliated_clubs_dropdown;
                        for (var prop_data in affiliated_clubs_dropdown_value){
                            this.UpdateProfile.club = prop_data;
                            this.profileData.info.club_name = resp.affiliated_clubs_dropdown[prop_data];
                        }
                    }
                      this.tempProfileData.club = this.profileData.info.club_name;

                    if(this.subdomain == 'waterski' && (this.profileData.info.membership.id == 13 || this.profileData.info.membership.member_type_id == 5)){
                        this.digitalView = false;

                    }

                      if(this.subdomain == "shooting" && this.profileData.info.membership.member_type_id != 7){
                        if(this.profileData.info.personal.cmp_no == null && this.profileData.info.personal.nra_no == null &&
                            this.profileData.info.personal.sasp_no == null && this.profileData.info.personal.sctp_no == null){
                          this.displayMissingMemFields = true;
                          this.alertMessage = "Please fill out the CMP, NRA and SSSF Membership #";
                        }
                      }

                    if(!this.getSiteProps('general.member_profile_addition_info') && this.getSiteProps('general.member_profile_addition_info') != undefined){
                      this.digitalInfoView = false;
                    }

                      if(this.subdomain == 'alliancefastpitch') {
                        if ([1, 2].indexOf(this.profileData.info.registration[0].membership_id) != -1) this.isChild = true;

                        this.UpdateProfile.jersey_number = this.profileData.info.personal.jersey_number;
                        this.UpdateProfile.graduation_year = this.profileData.info.personal.graduation_year;
                        this.UpdateProfile.throws = this.profileData.info.personal.throws;
                        this.UpdateProfile.bats = this.profileData.info.personal.bats;
                        this.UpdateProfile.primary_position = this.profileData.info.personal.primary_position;
                        this.UpdateProfile.secondary_position = this.profileData.info.personal.secondary_position;
                        this.UpdateProfile.agl_player_id = this.profileData.info.personal.agl_player_id;
                        this.UpdateProfile.college_committed = this.profileData.info.personal.college_committed;
                        if(this.profileData.info.personal.college_committed != null || (this.profileData.info.personal.college_committed != undefined )){
                          this.collegeCommitted =1;
                          this.college_list =1;
                        }else{
                          this.collegeCommitted =2;
                          this.college_list =2;
                        }
                        this.tempProfileData.college_list = this.college_list;
                        this.tempProfileData.collegeCommitted = this.collegeCommitted;
                        this.parentCitizenship =  this.profileData.info.parent_citizenship != null? 1:2;
                        this.eligibleCitizenship = this.profileData.info.eligible_citizenship != null?1:2;
                        this.athleteCitizenship = this.profileData.info.athlete_citizenship != null?1:2;
                        this.UpdateProfile.is_parent_citizenship = this.profileData.info.parent_citizenship != null? 1:2;
                        this.UpdateProfile.is_eligible_citizenship = this.profileData.info.eligible_citizenship != null?1:2;
                        this.UpdateProfile.is_athlete_citizenship = this.profileData.info.athlete_citizenship != null?1:2;
                        this.UpdateProfile.parent_citizenship = this.profileData.info.parent_citizenship;
                        this.UpdateProfile.eligible_citizenship = this.profileData.info.eligible_citizenship;
                        this.UpdateProfile.athlete_citizenship = this.profileData.info.athlete_citizenship;

                        this.UpdateProfile.parent_first_name = this.profileData.info.contact_details.first_name;
                        this.UpdateProfile.parent_last_name = this.profileData.info.contact_details.last_name;
                        this.UpdateProfile.parent_middle_name = this.profileData.info.contact_details.middle_name;
                        this.profileData.info.disciplines = '';
                      }

                    }).catch((error) =>{
                    console.log(error);
                });
            },
            changeMagazineSubscription(value){
                axios.post(this.basePath + 'api/member/subscribe',this.subscription,{ headers:this.memberHeaders })
                    .then(function (response) {
                        this.loadMembersInfo();
                        //this.profileData.info.printed_magazine = 1;
                          this.$refs['my-modal'].hide()
                        this.alertMessage =response.data.message;
                        //console.log(response);
                        this.displayAlert = true;
                    }.bind(this))
                    .catch(function (error) {
                        console.log(error);
                    });
                    console.log("disabled");
                },
            updatePersonalInfo(bvModalEvt){
                // Prevent modal from closing
                bvModalEvt.preventDefault();

                if(this.validatePersonalInfo() == true){
                    this.updateMemberInfo();
                }
            },
            getAge(birth_date) {
                if(birth_date == null) return 0;
                let birth_date_arr = birth_date.split('/');
                let today_date = new Date();
                let today_year = today_date.getFullYear();
                let today_month = today_date.getMonth();
                let today_day = today_date.getDate();
                let age = today_year - birth_date_arr[2];

                if ( today_month < (birth_date_arr[0] - 1))
                {
                    age--;
                }
                if (((birth_date_arr[0] - 1) == today_month) && (today_day < birth_date_arr[1]))
                {
                    age--;
                }
                return age;
            },
            validatePersonalInfo(){
                this.errors = {};
                this.valid = false;

                var validTitle = {valid:true}, validGender ="", validFederation="", validEmail="", validPhone2="", validPhone1="", validMiddleName ={valid:true},validPrimaryPosition = {valid:true}, validThrow = {valid:true}, validBats = {valid:true},  validJerseyNumber = {valid:true}, validGraduationYear = {valid:true}, validTeam = {valid:true};
                // const validTitle = validateTitle(this.UpdateProfile.title);
                // this.errors.title = validTitle.error;

                const validFirstName = validateName(this.UpdateProfile.first_name);
                this.errors.first_name = validFirstName.error;

                const validLastName = validateLastName(this.UpdateProfile.last_name);
                this.errors.last_name = validLastName.error;

                validMiddleName = validateOptionalTextFields(this.UpdateProfile.middle_name, "Middle name");
                this.errors.middle_name = validMiddleName.error;

                let age = this.isUnderAgeDivision(this.UpdateProfile.dob);
                var age_divisionArr = this.getSiteProps('memberRegistration.team.fields.club_info.age_division.options');
                const validDob = validateDob(this.UpdateProfile.dob, this.profileData.info.membership.id, age, this.digitalInfoView, this.tempProfileData.age_division, age_divisionArr, this.isChild);
                this.errors.dob = validDob.error;

                if(this.props_fields.personal.gender) {
                  validGender = validateGender(this.UpdateProfile.gender);
                  this.errors.gender = validGender.error;
                }else{
                  validGender = {valid:true};
                }

                if(this.digitalInfoView){
                  validEmail = validateEmail(this.UpdateProfile.email);
                  this.errors.email = validateEmail.error;

                  validPhone1 = validatePhone(false, this.UpdateProfile.phone_1,this.validTelinput);
                  this.errors.phone_1 = validPhone1.error;
                  validPhone2 = validatePhone2(false, this.UpdateProfile.phone_2,this.validTelinput2);
                  this.errors.phone_2 = validPhone2.error;
                }
              if(this.isChild) {
                validPrimaryPosition = validateSelectOption(this.UpdateProfile.primary_position, "Primary");
                this.errors.primary_position = validPrimaryPosition.error;

                validThrow = validateRadio(this.UpdateProfile.throws);
                this.errors.throws = validThrow.error;

                validBats = validateRadio(this.UpdateProfile.bats);
                this.errors.bats = validBats.error;

                validJerseyNumber = validateJerseyNumber(this.UpdateProfile.jersey_number);
                this.errors.jersey_number = validJerseyNumber.error;

                validGraduationYear = validateSelectOption(this.UpdateProfile.graduation_year);
                this.errors.graduation_year = validGraduationYear.error;
              }
              var isValid = true;
              if(!this.digitalInfoView && this.isChild && (!validFirstName.valid || !validLastName.valid || !validDob.valid || !validTitle.valid || !validMiddleName.valid || !validPrimaryPosition.valid || !validThrow.valid || !validBats.valid || !validJerseyNumber.valid || !validGraduationYear.valid)){
                isValid = false;

              }

                if (validFirstName.valid && validLastName.valid && validGender.valid && validDob.valid && validTitle.valid && validMiddleName.valid && isValid) {
                    let dob = this.getAge(this.UpdateProfile.dob);
                  if (!this.isNotalliancefastpitch && this.isChild) {
                    dob = age;
                  }
                    if(!this.validateDate(this.UpdateProfile.dob)){
                        this.valid = false;
                        this.errors.dob = 'Invalid Date';
                    } else {
                        this.errors.dob = '';
                        if(this.props_membership.age){
                            if(this.profileData.info.membership.member_type_id != 3){
                                if(this.props_membership.age.compare=="above" && dob < this.props_membership.age.value) {
                                    this.valid = false;
                                    this.errors.dob = this.props_membership.age.error_msg;
                                } else if(this.props_membership.age.compare=="below" && dob > this.props_membership.age.value) {
                                    this.valid = false;
                                    this.errors.dob = this.props_membership.age.error_msg;
                                }  else if(!Object.is(Math.abs(dob), +dob)) {
                                  this.valid = false;
                                  this.errors.dob = "Invalid Date";
                                } else {
                                    this.errors.dob = '';
                                    this.valid = true;
                                }
                            } else {
                                if(
                                    this.profileData.info.personal != undefined &&
                                    this.profileData.info.personal.relation != undefined &&
                                    this.profileData.info.personal.relation == 0 && dob < this.props_membership.age.value
                                ){
                                    this.valid = false;
                                    this.errors.dob = this.props_membership.age.error_msg;
                                } else if(
                                    this.profileData.info.personal != undefined &&
                                    this.profileData.info.personal.relation != undefined &&
                                    this.profileData.info.personal.relation == 1 && dob < this.props_membership.age.value
                                ){
                                    this.valid = false;
                                    this.errors.dob = this.props_membership.age.spouse_msg;
                                } else if (
                                    this.profileData.info.personal != undefined &&
                                    this.profileData.info.personal.relation != undefined &&
                                    this.profileData.info.personal.relation == 2 && dob >= this.props_membership.age.child_age
                                ){
                                    this.valid = false;
                                    this.errors.dob = this.props_membership.age.child_msg;
                                } else {
                                    this.valid = true;
                                }
                            }
                        } else {
                            this.valid = true;
                        }
                    }
                }

                if(this.digitalInfoView && this.props_fields.personal != undefined && this.props_fields.personal.veteran_status != undefined){
                    const validMilitary = validateRadio(this.UpdateProfile.veteran_status, "Military Status");
                    this.errors.veteran_status = validMilitary.error;

                    if(!validMilitary.valid) this.valid = false;
                }

                if(this.digitalInfoView && this.props_fields.general != undefined && this.props_fields.general.sport_discipline != undefined && this.props_fields.general.sport_discipline.first_choice != undefined){
                    const validFirstChoice = validateSelect(this.UpdateProfile.first_choice,'',false);
                    if(Array.isArray(this.UpdateProfile.first_choice)){
                        if(this.UpdateProfile.first_choice.length == 0){
                            validFirstChoice.valid = false;
                            validFirstChoice.error = "This field is required";
                        } else {
                            validFirstChoice.valid = true;
                            validFirstChoice.error = "";
                        }
                    }

                    this.errors.first_choice = validFirstChoice.error;

                    if(!validFirstChoice.valid&&!(this.subdomain=='shooting'&&this.profileData.info.membership.member_type_id==7)) this.valid = false;
                }


              if(this.digitalInfoView) {
                // if(!validEmail.valid || !validPhone1.valid){
                //   this.alertMessage = "E-Mail or Mobile number is missing.<br> Please fill update.";
                //   this.displayAlert = true;
                //   this.setDangerBg();
                //   return this.valid = false;
                // }
                if (this.subdomain == "waterski") {
                  validFederation = validateSelect(this.UpdateProfile.home_federation);
                  if (Array.isArray(this.UpdateProfile.home_federation)) {
                    if (this.UpdateProfile.home_federation.length == 0) {
                      validFederation.valid = false;
                      validFederation.error = "This field is required";
                    } else {
                      validFederation.valid = true;
                      validFederation.error = "";
                    }
                  }
                  this.errors.home_federation = validFederation.error;
                  if (!validFederation.valid) this.valid = false;

                  const validSafeSportForeignFederationId = validateFedration(this.UpdateProfile.skier_license);
                  console.log('fed id:', this.UpdateProfile.skier_license);
                  if (!validSafeSportForeignFederationId.valid && this.UpdateProfile.home_federation != 'USA') {
                    validSafeSportForeignFederationId.valid = false;
                    this.errors.skier_license = validSafeSportForeignFederationId.error;
                  } else if (this.UpdateProfile.skier_license != null && this.UpdateProfile.home_federation == 'USA') {
                    validSafeSportForeignFederationId.valid = true;
                    validSafeSportForeignFederationId.error = "";
                    this.UpdateProfile.skier_license = "";
                  } else {
                    validSafeSportForeignFederationId.valid = true;
                    validSafeSportForeignFederationId.error = "";
                  }
                  this.errors.skier_license = validSafeSportForeignFederationId.error;
                  if (!validSafeSportForeignFederationId.valid) this.valid = false;
                }
              }
                return this.valid;
            },
          resetprofileData(){
            this.errors = {};
            this.UpdateProfile.title = this.tempProfileData.title;
            this.UpdateProfile.first_name = this.tempProfileData.first_name;
            this.UpdateProfile.middle_name = this.tempProfileData.middle_name;
            this.UpdateProfile.last_name = this.tempProfileData.last_name;
            this.UpdateProfile.dob = this.tempProfileData.dob;
            this.UpdateProfile.gender = this.tempProfileData.gender;
            this.UpdateProfile.team_id = Object.keys(this.tempProfileData.affiliated_clubs_dropdown);
            this.UpdateProfile.veteran_status = this.tempProfileData.veteran_status;
            this.UpdateProfile.first_choice = this.tempProfileData.first_choice;
            this.UpdateProfile.second_choice = this.tempProfileData.second_choice;
            this.UpdateProfile.home_federation = this.tempProfileData.personal.home_federation ;
            this.UpdateProfile.graduation_year = this.tempProfileData.personal.graduation_year ;
            this.UpdateProfile.jersey_number = this.tempProfileData.personal.jersey_number ;
            this.UpdateProfile.primary_position = this.tempProfileData.personal.primary_position;
            this.UpdateProfile.secondary_position = this.tempProfileData.personal.secondary_position;
            this.UpdateProfile.agl_player_id = this.tempProfileData.personal.agl_player_id;
            this.UpdateProfile.throws = this.tempProfileData.personal.throws;
            this.UpdateProfile.bats = this.tempProfileData.personal.bats;
            this.college_list = this.tempProfileData.college_list;
            this.collegeCommitted = this.tempProfileData.collegeCommitted;
            this.UpdateProfile.college_committed = this.tempProfileData.personal.college_committed;
            this.UpdateProfile.club= Object.keys(this.tempProfileData.affiliated_clubs_dropdown);
          },
            updateAddressInfo(bvModalEvt){
                // Prevent modal from closing
                bvModalEvt.preventDefault();
                if(this.validateAddressInfo()){
                    this.updateMemberInfo();
                    // Hide the modal manually
                    this.$nextTick(() => {
                        this.$bvModal.hide('modal-center')
                    });
                }
            },
            validateAddressInfo(){
                this.errors = {};
                this.valid = false;
                var validState ='', validEmail = '', validPhone1 = '', validPhone2 = '';
                var validCheckParentCitizenship = {valid:true}, validCheckAthleteCitizenship = {valid:true}, validCheckEligibleCitizenship = {valid:true};
                var validParentCitizenship = {valid:true}, validAthleteCitizenship = {valid:true}, validEligibleCitizenship = {valid:true};
                const validAddress1 = validateAddressName(this.UpdateProfile.address1);
                this.errors.address1 = validAddress1.error;

                const validCountry = validateSelect(this.UpdateProfile.country);
                this.errors.country = validCountry.error;

                const validStateCode = validateSelect(this.UpdateProfile.state_code);
                validState = validateStateName(this.UpdateProfile.state);
                if(this.digitalInfoView) {

                  validEmail = validateEmail(this.UpdateProfile.email);
                  this.errors.email = validateEmail.error;

                  validPhone1 = validatePhone(false, this.UpdateProfile.phone_1,this.validTelinput);
                  this.errors.phone_1 = validPhone1.error;

                  if (validCountry.valid && this.UpdateProfile.country != 'Canada' && this.UpdateProfile.country != 'USA') {
                    this.errors.state = validState.error;
                  } else {
                    this.errors.state_code = validStateCode.error;
                  }
                }else {
                  validEmail = {valid:true};
                  validPhone1 = {valid:true};
                  validPhone2 = validatePhone2(false, this.UpdateProfile.phone_2,this.validTelinput2);
                  this.errors.phone_2 = validPhone2.error;
                  if (validCountry.valid && this.UpdateProfile.country != 'USA' || this.UpdateProfile.country != 'Canada') {
                    this.errors.state = validState.error;
                  } else {
                    this.errors.state_code = validStateCode.error;
                  }

                  validCheckParentCitizenship = validateRadio(this.UpdateProfile.is_parent_citizenship.toString(),"Parent/Guardian");
                  this.errors.is_parent_citizenship = validCheckParentCitizenship.error;

                  validCheckAthleteCitizenship = validateRadio(this.UpdateProfile.is_athlete_citizenship.toString(),"Child/Athlete");
                  this.errors.is_athlete_citizenship = validCheckAthleteCitizenship.error;
                  if (this.parentCitizenship==1) {
                    validParentCitizenship = validateSelectOption(this.UpdateProfile.parent_citizenship, "Parent/Guardian");
                    this.errors.parent_citizenship = validParentCitizenship.error;
                  }
                  if (this.athleteCitizenship==1) {
                    validAthleteCitizenship = validateSelectOption(this.UpdateProfile.athlete_citizenship, "Child/Athlete");
                    this.errors.athlete_citizenship = validAthleteCitizenship.error;
                  }

                  if(this.athleteCitizenship == 2 || this.parentCitizenship == 2) {
                    validCheckEligibleCitizenship = validateRadio(this.UpdateProfile.is_eligible_citizenship.toString(),"Child/Athlete Eligible");
                    this.errors.is_eligible_citizenship = validCheckEligibleCitizenship.error;

                    if (this.eligibleCitizenship==1) {
                      validEligibleCitizenship = validateSelectOption(this.UpdateProfile.eligible_citizenship, "Child/Athlete Eligible");
                      this.errors.eligible_citizenship = validEligibleCitizenship.error;
                    }
                  }
                }
                const validCity = validateName(this.UpdateProfile.city);
                this.errors.city = validCity.error;

                const validZip = validateZip(this.UpdateProfile.zip, this.UpdateProfile.country);
                this.errors.zip = validZip.error;

                if (validAddress1.valid && validCountry.valid
                    && validCity.valid && validZip.valid && validCheckParentCitizenship.valid &&
                validCheckAthleteCitizenship.valid && validCheckEligibleCitizenship.valid && validParentCitizenship.valid && validAthleteCitizenship.valid && validEligibleCitizenship.valid) {
                    this.valid = true;
                  if(this.digitalInfoView) {
                    if (this.UpdateProfile.country == 'USA') {
                      this.UpdateProfile.state = '';
                      if (!validStateCode.valid) {
                        this.valid = false;
                      }
                    } else if (!validState.valid) {
                      this.UpdateProfile.state_code = '';
                      this.valid = false;
                    }
                  }else{
                    if(this.UpdateProfile.country == 'USA' || this.UpdateProfile.country == 'Canada') {
                      this.UpdateProfile.state = '';
                      if(!validStateCode.valid) {
                        this.errors.state_code = validStateCode.error;
                        this.valid = false;
                      }
                    } else if(!validState.valid) {
                      this.UpdateProfile.state_code = '';
                      this.valid = false;
                    }
                  }
                }
              if(this.UpdateProfile.address_type=='Work')
              {
                const validCompany = validateName(this.UpdateProfile.company_name);
                this.errors.company_name = validCompany.error;
                if(!validCompany.valid) this.valid = false;
              }
                if(!validEmail.valid || !validPhone1.valid){
                    this.alertMessage = "E-Mail or Mobile number is missing.<br> Please fill update.";
                    this.displayAlert = true;
                    this.setDangerBg();
                    return this.valid = false;
                }
                return this.valid;
            },
            updateContactInfo(bvModalEvt){
                // Prevent modal from closing
                bvModalEvt.preventDefault()
                if(this.validateContactInfo()){
                    this.updateMemberInfo();
                    // Hide the modal manually
                    this.$nextTick(() => {
                        this.$bvModal.hide('modal-center3')
                    });
                }
            },
            validateContactInfo(){
                this.errors = {};
                this.valid = false;

                var validParentFirstName = {valid:true}, validParentLastName = {valid:true};

                const validEmail = validateEmail(this.UpdateProfile.email);
                this.errors.email = validEmail.error;

                const validPhone1 = validatePhone(true,this.UpdateProfile.phone_1, this.validTelinput);
                this.errors.phone_1 = validPhone1.error;

                const validPhone2 = validatePhone2(true,this.UpdateProfile.phone_2, this.validTelinput2);
                this.errors.phone_2 = validPhone2.error;

                if(this.isChild) {
                  validParentFirstName = validateName(this.UpdateProfile.parent_first_name);
                  this.errors.parent_first_name = validParentFirstName.error;

                  validParentLastName = validateLastName(this.UpdateProfile.parent_last_name);
                  this.errors.parent_last_name = validParentLastName.error;
                }

                if (validEmail.valid && validPhone1.valid && validParentFirstName.valid && validParentLastName.valid && validPhone2.valid){
                    this.valid = true;
                }

                return this.valid;

            },
            updateDemographicInfo(bvModalEvt){
                // Prevent modal from closing
                bvModalEvt.preventDefault()
                if(this.validateDemographicInfo()){
                    this.updateMemberInfo();
                    // Hide the modal manually
                    this.$nextTick(() => {
                        this.$bvModal.hide('edit-demographic')
                    });
                }
            },
            validateDemographicInfo(){
                this.errors = {};
                this.valid = false;

                const validDiversity = validateRadio(this.UpdateProfile.diversity_id);
                this.errors.diversity_id = validDiversity.error;

                

                const validEthnicity = validateRadio(this.UpdateProfile.ethnicity);
                if(this.props_fields.demographic != undefined && this.props_fields.demographic.ethnicity != undefined){
                    this.errors.ethnicity = validEthnicity.error;
                } else {
                    validEthnicity.valid = true;
                }

                const validDisability = validateRadio(this.UpdateProfile.disability);
                if(this.props_fields.demographic != undefined && this.props_fields.demographic.disability_status != undefined){
                    this.errors.disability = validDisability.error;
                } else {
                    validDisability.valid = true;
                }

                const validVeteranStatus = validateRadio(this.UpdateProfile.veteran_status);
                if(this.props_fields.demographic != undefined && this.props_fields.demographic.veteran_status != undefined){
                    this.errors.veteran_status = validVeteranStatus.error;
                } else {
                    validVeteranStatus.valid = true;
                }

                if (validDiversity.valid && validEthnicity.valid && validDisability.valid && validVeteranStatus.valid) {
                    this.valid = true;
                }

                if(this.props_fields.demographic != undefined && this.props_fields.demographic.primary_area_interest != undefined){
                    const validPrimaryAreaInterest = validateSelect(this.UpdateProfile.primary_area_interest);
                    this.errors.primary_area_interest = validPrimaryAreaInterest.error;
                    if(!validPrimaryAreaInterest.valid) this.valid = false;
                }

                if(this.props_fields.demographic != undefined && this.props_fields.demographic.category != undefined){
                    if(this.UpdateProfile.category != null){
                        const validCategory = validateSelect(this.UpdateProfile.category.toString());
                         this.errors.category = validCategory.error;
                        if(!validCategory.valid) this.valid = false;
                    }else{
                        this.errors.category = "Category field is required";
                        this.valid = false;
                    } 
                }

                if(this.props_fields.demographic != undefined && this.props_fields.demographic.hear_about != undefined){
                    if(this.UpdateProfile.hear_about != null){
                        const validHearAbout = validateSelect(this.UpdateProfile.hear_about.toString());
                        this.errors.hear_about = validHearAbout.error;
                        if(!validHearAbout.valid) this.valid = false;
                    }else{
                         this.errors.hear_about = "Hear about field is required";
                        this.valid = false;
                    }
                    
                }
                const validEmail = validateEmail(this.UpdateProfile.email);
                this.errors.email = validateEmail.error;

                const validPhone1 = validatePhone(false,this.UpdateProfile.phone_1,this.validTelinput);
                this.errors.phone_1 = validPhone1.error;
                
                if(!validEmail.valid || !validPhone1.valid){
                    this.alertMessage = "E-Mail or Mobile number is missing.<br> Please fill update.";
                    this.displayAlert = true;
                    this.setDangerBg();
                    return this.valid = false;
                }
                return this.valid;

            },
            updateMemberInfo(){
              if(!this.digitalInfoView){
                if(this.parentCitizenship==2)
                  this.UpdateProfile.parent_citizenship ='';

                if(this.athleteCitizenship==2)
                  this.UpdateProfile.athlete_citizenship ='';

                if(this.eligibleCitizenship==2)
                  this.UpdateProfile.eligible_citizenship ='';

                if(this.collegeCommitted == 2 || this.college_list == 2){
                  this.UpdateProfile.college_committed = '';
                }
              }
                let memberInfoData = JSON.parse(localStorage.getItem('member'));
                let updateUrl = this.basePath + 'api/member/my_profile';
                this.displayAlert = false;
                if( this.UpdateProfile.home_federation == 'USA') { this.UpdateProfile.skier_license = ''; }
                 this.UpdateProfile.printed_magazine=this.profileData.info.printed_magazine;
                this.UpdateProfile.membership_id = this.profileData.info.membership.id;
                axios.post(updateUrl, this.UpdateProfile,{ headers:this.memberHeaders })
                    .then(response => {
                      if(response.data.message == 'Duplicate member found'){
                        this.duplicateErrorForPersonal = 'A user with the same details already exists.';
                        this.$bvModal.show('modal-center1')
                        return "Duplicate";
                      }
                        this.alertMessage = "Member Information updated successfully";
                        this.displayAlert = true;
                        memberInfoData.full_name=response.data.data.full_name;
                        localStorage.setItem('member',JSON.stringify(memberInfoData));
                        this.updateLoggedUserInfo += 1;

                        this.loadMembersInfo();
                        this.$bvModal.hide('modal-center1')
                        console.log(response);
                        EventBus.$emit('changeProfileName', this.UpdateProfile.first_name+" "+this.UpdateProfile.last_name);
                    })
                    .catch(error => {
                        this.validationErrorAlert( error.response.data.errors);
                    });
              return "Success";
            },
            resetAlert(){
                this.displayAlert = false;
                this.removeDangerBg();
            },
            updateProfilePic(e) {
                e.preventDefault();
                this.$set(this.memberHeaders, 'content-type', 'multipart/form-data');
                let formData = new FormData();
                formData.append('member_id', this.profileData.info.id);
                formData.append('image', this.profilePic);
                axios.post(this.basePath + 'api/member/profile_pic', formData, {headers: this.memberHeaders})
                    .then(function (response) {
                        this.alertMessage = "Member profile picture changed successfully";
                        this.displayAlert = true;
                        var memberInfoData = JSON.parse(localStorage.getItem('member'));
                        memberInfoData.profile_pic=response.data.data.profile_pic;
                        localStorage.setItem('member',JSON.stringify(memberInfoData));
                        this.updateLoggedUserInfo += 1;
                        this.loadMembersInfo();
                    }.bind(this))
                    .catch(function (error) {
                        console.log(error);
                    });
                this.$nextTick(() => {
                    this.$bvModal.hide('profile_pic_modal')
                });
                this.$set(this.memberHeaders, 'content-type', 'application/json');
            },
            resetModal() {
                this.uploadPicFile = '';
                this.preview='';
            },
            onImageChange(e) {
                let file = e.target.files[0];
                this.profilePic = file;
                let reader = new FileReader();
                reader.onloadend = (file) => {
                    this.preview = reader.result;
                }
                reader.readAsDataURL(file);
            },
            getPreview(){
                let preview = (this.preview.length > 200) ? this.preview : '/dist/img/avatarimg.png' ;
                return preview;
            },
          isFutureDate(date) {
            const currentDate = new Date();
            return date > currentDate;
          },
          myChangeEvent(val){
            this.UpdateProfile.team_id=val;
            
          },
          mySelectEvent({id, text}){
            this.UpdateProfile.team_id=id;    
          },
          validateTelinput(obj){            
                this.validTelinput = obj.valid;
                if(obj.valid){
                    this.errors.company_phone = '';
                }
            },
            getDiversity()
            {
                axios.get(this.basePath+"api/getDiversity")
                    .then(response => {
                        this.diversities = response.data.data;
                    })
                    .catch(err => {
                        // Manage the state of the application if the request
                        // has failed
                        console.log(err);
                    })
            },
            getCategories(){
                axios.get(this.basePath + "api/categories")
                .then(response => {
                    this.categories = response.data.data;
                })
                .catch(error => {
                    console.log("Error in getting categories: ", JSON.stringify(error));
                });
            },
            refreshCard(event){
                this.refreshProgress = true;
                let member_id = this.profileData.info.id;
                this.displayAlert = false;
                axios.post(this.basePath+"api/member/refresh-card", { member: member_id })
                .then(res => {
                    this.alertMessage = "Member Card updated successfully";
                    this.displayAlert = true;
                    this.refreshProgress = false;
                    this.updateLoggedUserInfo += 1;
                    this.loadMembersInfo();
                })
                .catch(err => {
                    console.log(err);
                    this.refreshProgress = false;
                })
            },
            getClubs(){
                axios.get(this.basePath + "api/get_teams",{ headers:this.memberHeaders })
                .then(response => {
                    var clubs = response.data.data;
                    this.clubOptions = clubs;
                })
                .catch(error=>{
                    console.log("error geting clubs list")
                })
            },
            getMemberGroup()
            {
                let memberInfoData = JSON.parse(localStorage.getItem('member'));
                axios.get(this.basePath+'api/member/member-group/'+ memberInfoData.person_id,{ headers:this.memberHeaders })
                .then((res) => {
                    this.memberGroups = res.data.data;                            
                    this.memberGroupVisible = Object.keys( this.memberGroups ).length;                            
                })
                .catch((error) => {
                    console.log('Error:'+ error);               
                }).finally(() => {
                });
            },
           removeStateCode(){
              this.UpdateProfile.state = '';
              this.UpdateProfile.state_code = '';
           },
        messageFor(val){
            this.errors.gender = this.getSiteProps('gender_alert_message.'+val);
        },
        validateTelinput2(obj){
          this.validTelinput2 = obj.valid;
          if(obj.valid){
            this.errors.phone_2 = '';
          }
        },
    },
    mounted() {
        const retry = setInterval(() => {
            this.props_fields = this.getSiteProps('memberRegistration.individual.fields');
            if(this.props_fields) clearInterval(retry);
        }, 200);

        //this.getSafeSportUserApi();
        //this.getBackgroundScreeningApi();
        this.loadMembersInfo();
        this.getDiscipline(1);
        this.getClubs();
        this.getGroup();
        this.getDiversity();
        this.getCategories();
        this.getCurrentSeason();
        const retry1 = setInterval(() => {
            if(this.getSiteProps('group_model.visible')){
                this.getMemberGroup();
                clearInterval(retry1);
            }
        }, 200);
        const retry2 = setInterval(() => {
            this.props_tooltips = this.getSiteProps('tooltips');
            if(this.props_tooltips) clearInterval(retry);
        }, 200);

         this.subscription.printed_magazine = this.profileData.info.printed_magazine;
      if(this.subdomain == 'alliancefastpitch')
      {
        this.getCollegeCommittedsList();
      }
      const year = new Date().getFullYear();
      const yrs = year+20;
      const twoDigitYear = yrs.toString().substr(-2);
      const lengthOfYear = parseInt(20) + parseInt(twoDigitYear);
      this.GraduationyearArr = Array.from({length: lengthOfYear}, (value, index) => yrs - index);
    },
    computed : {
        familyCat(){
            let cats = { 0: 'HOH', 1: 'Spouse', 2: 'Child', 3: 'Dependent' };
            if(this.profileData.info.membership != undefined && this.profileData.info.membership.member_type_id != undefined && this.profileData.info.membership.member_type_id == 3){
                return cats[this.profileData.info.personal.relation];
            }
            return "";
        },
        enableMagazine(){
            return this.getSiteProps('membershipInfoEdit.printedMagazine') && this.profileData.info.membership.id != 7 && this.digitalInfoView && this.profileData?.info?.membership?.id != 13 && (this.profileData?.info?.membership?.id != 5 || this.profileData?.info?.personal_details?.relation == 0);         
        }
    },
    updated(){
        this.printed_magazine = this.profileData.info.printed_magazine;
    }
}

</script>

<style scoped>
.hovereffect {
    position: relative;
}
.avatar{
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
}
.overlay {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}
.hovereffect:hover .avatar {
    opacity: 0.3;
}
.hovereffect:hover .overlay {
    opacity: 1;
}
.text {
    background-color: #4CAF50;
    color: white;
    font-size: 16px;
    padding: 5px;
}
.group_tag_title strong{
    font-size: 16px;
}
</style>
